import React from 'react'
import { connectInfiniteHits } from 'react-instantsearch-dom';
import Grid from '@material-ui/core/Grid';
import { Button, Typography } from '@material-ui/core';

const TemplatedProductHits = (props) => {
  if(props.hits.length > 0) {
    return (
      <div style={{marginRight: -1}}>
        <Grid container spacing={0}>
            {props.hits.map((row, index) => {
              return (
                <Grid 
                  key={index} 
                  item 
                  xs={12}
                  md={props.columns}
                  style={props.itemStyles}
                >
                  <Button
                    onClick={() => {
                      if(props.action) {
                        props.action(row);
                      }
                    }}
                    style={{
                      padding: 0,
                      position: 'relative',
                      background: 'transparent',
                      borderRadius: 0,
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 5,
                      paddingRight: 5,
                      border: '1px solid #d7d7d7',
                      borderLeft: 0,
                      borderTop: 0,
                      display: 'inline'
                    }}
                    fullWidth
                  >
                    <div>
                      <Typography variant="h6" style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}>
                        {row.name}
                      </Typography>
                      <Typography variant="subtitle2">
                        {row.price.formattedValue}
                      </Typography>
                    </div>
                  </Button>
                </Grid>
              )
            })}
        </Grid>
      </div>
    )
  }
  
  return (
    <div>
      <p>No results</p>
    </div>
  )
}

const InfiniteHitsProducts = connectInfiniteHits(TemplatedProductHits);

export { InfiniteHitsProducts, TemplatedProductHits };
