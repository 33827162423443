import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import isUndefined from 'lodash/isUndefined';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import size from 'lodash/size';

const DialogTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    background: '#f6ad1b'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.primary.dark,
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close" 
          className={classes.closeButton} 
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function ViewTab(props) {
  const { open, updateOpen, tab, unselectAll, calculatePrice } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [total, setTotal] = useState({});

  function onDeleteProduct(row, index) {
    const products = data.products;
    delete products[row.id];
    firebase.firestore().collection('tabs').doc(`${tab.merchant_id}-${tab.user_id}`).update({
      products: products,
      total: calculatePrice(products)
    });
  }

  function handleClose() {
    updateOpen(false);
    unselectAll();
  };

  useEffect(() => {
    if(deleting) {
      firebase.firestore().collection('tabs')
      .doc(`${tab.merchant_id}-${tab.user_id}`)
      .delete()
      .then(() => {
        unselectAll();
        updateOpen(false);
        unselectAll();
      })
    }
  }, [deleting, tab.merchant_id, tab.user_id, updateOpen, unselectAll])

  function onDeleteTab() {
    setLoading(true);
    setDeleting(true);
  }

  function onSend() {
    firebase.firestore()
      .collection('tabs')
      .doc(`${tab.merchant_id}-${tab.user_id}`)
      .update({
        status: 'sent'
      })
  }

  useEffect(() => {
    function handleSnap(doc) {
      setTotal(calculatePrice(doc.data().products));
      setData(doc.data());
      setLoading(false);
    }

    let unsub = firebase.firestore()
      .collection('tabs')
      .doc(`${tab.merchant_id}-${tab.user_id}`)
      .onSnapshot(handleSnap);

    return unsub;
  }, [tab, calculatePrice])

  if(loading || deleting) {
    return <div />
  }

  return (
    <div>
      <Dialog
        fullScreen
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle onClose={handleClose} id="form-dialog-title">
          Tab: {data.display_name ? data.display_name : data.name} 
          <IconButton onClick={() => onDeleteTab()}>
            <DeleteIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent 
          style={{
            minHeight: 250,
            marginTop: '0.25rem'
          }}
        >
          {(() => {
            if(isUndefined(data.products) || size(data.products) < 1) {
              return (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      No items.
                    </Typography>
                  </Grid>
                </Grid>
              )
            }

            return (
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          padding="checkbox"
                        >
                          Actions
                        </TableCell>
                        <TableCell>Item Name</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell align="right">Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(data.products).map((key, index) => {
                        const row = data.products[key];
                        return (
                          <TableRow key={index}>
                            <TableCell
                              align="left"
                              padding="checkbox"
                            >
                              <IconButton onClick={() => onDeleteProduct(row, index)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">
                                {row.name}
                              </Typography>    
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">
                                {row.qty}
                              </Typography>    
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="body1">
                                {row.price.formattedValue}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{
                    position: 'fixed'
                  }}>
                    <br />
                    <Typography gutterBottom variant="h5">
                      Total: {total.formatted}
                    </Typography>
                    <Typography gutterBottom variant="subtitle1">
                      Status: <span style={{textTransform: 'uppercase'}}>{data.status}</span>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            );
          })()}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {(data.status === 'sent') && <Button
                style={{
                  fontSize: '1.25rem'
                }}
                fullWidth
                variant="contained" 
                onClick={onSend} 
                color="secondary"
              >
                Send Again
              </Button>}
              {(data.status === 'open') && <Button
                style={{
                  fontSize: '1.25rem'
                }}
                fullWidth
                variant="contained" 
                onClick={onSend} 
                color="secondary"
              >
                Send
              </Button>}
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{
                  fontSize: '1.25rem'
                }}
                fullWidth 
                variant="contained" 
                onClick={handleClose} 
                color="default"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withRouter(ViewTab)