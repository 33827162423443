import React from 'react';
import TextField from '@material-ui/core/TextField';
import SelectComponent from './inputs/SelectComponent';
import BooleanComponent from './inputs/BooleanComponent';
import DollarComponent from './inputs/DollarComponent';
import UniqueInputComponent from './inputs/UniqueInputComponent';
import AssetSelectComponent from './inputs/AssetSelectComponent';
import isUndefined from 'lodash/isUndefined';
import firebase from 'firebase/app';
import 'firebase/firestore';

import Grid from '@material-ui/core/Grid';

export default function(props) {
  const { schema, handleFieldChange, values, errors, changeError, merchantId, disabled, itemId } = props;
  // useEffect(() => {
  //   console.log(schema);
  // }, [])

  function isFieldSelectAsset(field) {
    switch (field.type.value) {
      case 'select-quantity':
      case 'select-stock':
      case 'select-product':
        return true;
      default:
        return false;
    }
  }

  return (
    <Grid direction="row" container spacing={1}>
      {schema.properties.map(field => (
        <Grid item xs={isFieldSelectAsset(field) ? 12 : 6} key={field.fieldId}>
          {(() => {
            if(field.type.value === 'select') {
              return <SelectComponent 
                data={field.options} 
                isMulti={field.multiple ? field.multiple : false} 
                isCreatable={false} 
                fieldId={field.fieldId}
                label={field.fieldName}
                placeholder={field.placeholder}
                required={field.required}
                onChange={(name, value) => handleFieldChange(field, value)}
                value={isUndefined(values[field.fieldId]) ? '' : values[field.fieldId] }
                error={isUndefined(errors[field.fieldId]) ? undefined : errors[field.fieldId]}
                key={field.fieldId}
                disabled={disabled}
              />
            }
        
            if(field.type.value === 'tag') {
              return <SelectComponent 
                data={field.options} 
                isMulti={true} 
                isCreatable={true} 
                fieldId={field.fieldId}
                label={field.fieldName}
                placeholder={field.placeholder}
                required={field.required}
                onChange={(name, value) => handleFieldChange(field, value)}
                value={isUndefined(values[field.fieldId]) ? '' : values[field.fieldId] }
                firestoreRef={firebase.firestore()
                  .collection('attribute')
                  .doc(merchantId)
                  .collection('items')
                  .doc(field.fieldId)}
                error={isUndefined(errors[field.fieldId]) ? undefined : errors[field.fieldId]}
                key={field.fieldId}
                disabled={disabled}
              />
            }
        
            if(field.type.value === 'unique') {
              return <UniqueInputComponent
                firestoreRef={schema.dataRef}
                error={isUndefined(errors[field.fieldId]) ? false : errors[field.fieldId].error}
                helperText={isUndefined(errors[field.fieldId]) ? '' : errors[field.fieldId].helperText}
                value={isUndefined(values[field.fieldId]) ? '' : values[field.fieldId]}
                required={field.required}
                fieldName={field.fieldName}
                onChange={(name, event) => handleFieldChange(field, event.target.value.toUpperCase())}
                fieldId={field.fieldId}
                changeError={changeError}
                key={field.fieldId}
                disabled={disabled}
                merchantId={merchantId}
                itemId={itemId}
                autoFocus
              />
            }
        
            if(field.type.value === 'checkbox') {
              return <BooleanComponent 
                error={isUndefined(errors[field.fieldId]) ? false : errors[field.fieldId].error}
                value={isUndefined(values[field.fieldId]) ? '' : values[field.fieldId]}
                required={field.required}
                fieldName={field.fieldName}
                onChange={(name, event) => handleFieldChange(field, event.target.checked)}
                fieldId={field.fieldId}
                key={field.fieldId}
                disabled={disabled}
              />
            }
        
            if(field.type.value === 'dollar') {
              return <DollarComponent
                raw={field.type.raw}
                error={isUndefined(errors[field.fieldId]) ? false : errors[field.fieldId].error}
                helperText={isUndefined(errors[field.fieldId]) ? '' : errors[field.fieldId].helperText}
                value={isUndefined(values[field.fieldId]) ? '' : values[field.fieldId]}
                required={field.required}
                fieldName={field.fieldName}
                onChange={(name, event) => handleFieldChange(field, event.target.value)}
                fieldId={field.fieldId}
                key={field.fieldId}
                disabled={disabled}
              />
            }

            if(field.type.value === 'select-stock') {
              return <AssetSelectComponent 
                assetType={{value: "stock", label: "Stock"}} 
                key={field.fieldId} 
                onChange={(text, id, price, cost) => handleFieldChange(field, text, id, price, cost)}
                value={values[field.fieldId]}
                disabled={disabled}
              />
            }
        
            if(field.type.value === 'select-product') {
              return <AssetSelectComponent 
                assetType={{value: "product", label: "Product"}} 
                key={field.fieldId}
                onChange={(text, id, price, cost) => handleFieldChange(field, text, id, price, cost)}
                value={values[field.fieldId]}
                disabled={disabled}
              />
            }
        
            if(field.type.value === 'select-quantity') {
              return <AssetSelectComponent 
                assetType={{value: "quantity", label: "Quantity"}} 
                key={field.fieldId}
                onChange={(text, id, price, cost) => handleFieldChange(field, text, id, price, cost)}
                value={values[field.fieldId]}
                disabled={disabled}
              />
            }
        
            return <TextField
              key={field.fieldId}
              required={field.required}
              margin="normal"
              id={field.fieldId}
              label={field.fieldName}
              placeholder={field.placeholder}
              type={field.type.value}
              fullWidth
              autoComplete={'off'}
              value={isUndefined(values[field.fieldId]) ? '' : values[field.fieldId]}
              onChange={(event) => handleFieldChange(field, event.target.value)}
              error={isUndefined(errors[field.fieldId]) ? false : errors[field.fieldId].error}
              helperText={isUndefined(errors[field.fieldId]) ? '' : errors[field.fieldId].helperText}
              disabled={(disabled || field.disabled)}
              multiline={field.multiline}
            />
          })()}
        </Grid>
      ))}
    </Grid>
  )
}