import firebase from 'firebase/app';
import 'firebase/firestore';

function report(merchantId) {
  return {
    title: 'report',
    asset: 'report',
    canAdd: true,
    canEdit: false, 
    canDelete: false,
    properties: [],
    default: [
      {
        type: {
          label: 'Text',
          value: 'Text'
        },
        fieldId: "reportName",
        fieldName: "Report Name",
        placeholder: "type something amazing...",
        required: true,
        priority: 100
      },
      {
        type: {
          label: 'Select',
          value: 'select'
        },
        multiple: false,
        fieldId: "fieldItem",
        fieldName: "Report Type",
        placeholder: "Stock / Product",
        required: true,
        priority: 0,
        options: [
          {
            value: 'value-inventory',
            label: 'Value Of Inventory'
          }
        ]
      },
      {
        type: {
          label: 'Text',
          value: 'Text'
        },
        fieldId: "results",
        fieldName: "Results",
        placeholder: "type something amazing...",
        required: false,
        priority: 100,
        disabled: true,
        multiline: true
      },
    ],
    schemaRef: firebase.firestore()
      .collection('attribute')
      .doc(merchantId)
      .collection('items')
      .where("fieldItem", "array-contains", {label: "report", value: "report"}),
    dataRef:firebase.firestore()
      .collection('report')
      .doc(merchantId)
  }
}

export default report;