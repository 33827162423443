import React, {useState, useEffect} from 'react';
import WriteFormComponent from './WriteFormComponent';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import InitialValues from '../helpers/InitialValues';
import ValidateFields from '../helpers/ValidateFields';
import FormatFields from '../helpers/FormatFields';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { withSnackbar } from 'notistack';
import compose from 'recompose/compose';
import { withStyles, CircularProgress } from '@material-ui/core';


const styles = theme => ({
  container: {
    margin: '30px 16px'
  },
  grid: {
    padding: 20,
    backgroundColor: theme.palette.secondary.main
  }
})

function RelationshipsWriteComponent(props) {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const {schema, merchantId, stockId, stockTitle, classes} = props;

  useEffect(() => {
    const newValues = InitialValues(schema);
    newValues['selectStock-id'] = stockId;
    newValues['selectStock'] = stockTitle;
    setValues(newValues);
  }, [schema, stockId, stockTitle])

  function changeError(error, fieldId)  {
    let newErrors = {};
    newErrors[fieldId] = error;
    setErrors(newErrors);
  }

  function handleFieldChange(field, fieldValue, row) {
    let newValues = values;
    const singleValue = FormatFields(field, fieldValue);
    newValues[field.fieldId] = singleValue;
    if(!isUndefined(row)) {
      newValues[`${field.fieldId}-id`] = row.id;
      // newValues['price'] = row.price;
      newValues['cost'] = row.cost;
    }
    setValues({...newValues});
  }

  function done(e) {
    setLoading(true);
    e.preventDefault();
    let isValid = true;
    let newErrors = {};
    forEach(schema.properties, (data) => {
      // We're expecting validateField to return true if there is an issue
      const SingleError = ValidateFields(data, values[data.fieldId], errors);
      newErrors[data.fieldId] = SingleError;
      if(isValid && isUndefined(SingleError)) {
        isValid = false;
      }
      if (isValid && SingleError.error) {
        isValid = false;
      };
    });
    setErrors(newErrors);
    if(!isValid || isUndefined(values['selectStock-id'])) {
      props.enqueueSnackbar("Oops! Something doesn't look right.", { 
        variant: 'error',
      });
      setLoading(false);
      return;
    }


    
    schema.dataRef.collection('items').add({...values, createdAt: firebase.firestore.FieldValue.serverTimestamp()})
      .then((data) => {
        props.enqueueSnackbar("Successfully added.");
        const newValues = InitialValues(schema);
        newValues['selectStock-id'] = stockId;
        newValues['selectStock'] = stockTitle;
        setValues(newValues);
        setLoading(false);
      })
      .catch(() => {
        props.enqueueSnackbar("Oops! Something doesn't look right.", { 
          variant: 'error',
        });
        setLoading(false);
      })
  }

  return (
    <div className={classes.container}>
      {(() => {
        if(loading) {
          return (
            <Grid 
              spacing={0}
              container 
              alignItems="center"
              className={classes.grid}
            >
              <Grid item xs={12}>
                <CircularProgress />
              </Grid>
            </Grid>
          )
        }

        return (
          <form onSubmit={done}>
            <Grid 
              spacing={2}
              container 
              alignItems="center"
              className={classes.grid}
            >
              <Grid item xs={12} sm={9}>
                <WriteFormComponent
                  schema={schema} 
                  handleFieldChange={handleFieldChange}
                  values={values}
                  errors={errors}
                  changeError={changeError}
                  merchantId={merchantId}
                  itemExists={false}
                  itemId={undefined}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button variant="contained" type="submit">
                  Done
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      })()}
    </div>
  )
}

export default compose(
  withSnackbar,
  withStyles(styles)
)(RelationshipsWriteComponent);