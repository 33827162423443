import React, {useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Check from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core'


const styles = theme => ({
  addGridButtons: {
    minWidth: 115
  },
  gridButtons: {
    minWidth: 110
  },
  addNew: {
    marginBottom: 20,
  }
})

function EditOptionsComponent(props) {
  const [data, setData] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const { relationRef } = props;

  useEffect(() => {
    let unsub = () => {};
    unsub = relationRef.onSnapshot((query) => {
      const newData = [];
      query.forEach((o) => {
        newData.push({...o.data(), id: o.id});
      });
      setData(newData);
    });
    return unsub;
  }, [relationRef])


  function updateItem(id) {
    const value = document.getElementById(id).value;
    relationRef.doc(id).update({label: value});
  }

  function deleteItem(id) {
    relationRef.doc(id).delete();
  }
  
  function addItem() {
    const value = document.getElementById('addNew').value;
    relationRef.add({label: value});
    document.getElementById('addNew').value = ''
  }

  const { classes } = props;

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Button className={classes.addNew} variant="outlined" fullWidth onClick={() => setAddNew(!addNew)}>
            Add New
          </Button>
        </Grid>
        <Grid item xs={12}>
          {addNew && <Grid container spacing={8} >
            <Grid item className={classes.addGridButtons}>
              <IconButton onClick={() => addItem()}>
                <Check />
              </IconButton>
            </Grid>
            <Grid item style={{flexGrow: 1}}>
              <TextField
                id={`addNew`}
                defaultValue={''}
                fullWidth
              />
            </Grid>
          </Grid>}
        </Grid>
      </Grid>
      <Grid direction="column" container spacing={10}>
        {data.map((row, index) => {
          return (
            <Grid 
              key={row.id}
              item
            >
              <Grid container spacing={4}>
                <Grid item className={classes.gridButtons}>
                  <IconButton onClick={() => updateItem(row.id)}>
                    <Check />
                  </IconButton>
                  <IconButton onClick={() => deleteItem(row.id)}>
                    <Delete />
                  </IconButton>
                </Grid>
                <Grid item style={{flexGrow: 1}}>
                  <TextField
                    id={row.id}
                    defaultValue={row.label}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default withStyles(styles)(EditOptionsComponent);