import React from 'react';
import { Button, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/Delete';

import firebase from 'firebase/app';
import 'firebase/firestore';
import LogoIcon from '../theme/LogoIcon';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%'
  },
  button: {
    borderRadius: 0,
    height: 50
  },
  title: {
    margin: 10,
    textAlign: 'center',
    // textTransform: 'uppercase'
  },
  container: {
    height: '100%'
  },
  item: {
    width: '100%'
  }
});


const ManageTabComponent = (props) => {
  const { selectedProducts, merchantRef, updateSelectedUser } = props;
  const classes = useStyles();

  console.log(props);

  function removeItem(key) {
    merchantRef.update({
      [`products.${key}`]: firebase.firestore.FieldValue.delete()
    });
  }

  function addToTab() {
    updateSelectedUser({});
  }

  return (
    <div
      className={classes.root}
    >
      <Grid 
        container 
        direction="column"
        justify="space-between"
        className={classes.container}
      >
        <Grid item className={classes.item}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    height: 78
                  }}
                >
                  Name
                </TableCell>
                <TableCell 
                  align="right"
                  style={{
                    height: 78
                  }}
                >
                  Price ($)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(selectedProducts).map((key, index) => {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      {selectedProducts[key].name} x {selectedProducts[key].qty}
                      <IconButton size="small" onClick={() => removeItem(key)}>
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                    {selectedProducts[key].price.formattedValue}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Grid>
        <Grid item className={classes.item}>
          <Button 
            variant="contained"
            color="default"
            fullWidth
            className={classes.button}
            onClick={() => updateSelectedUser({})}
          >
            Back
          </Button>
          {/* <Button 
            variant="contained"
            fullWidth
            disabled 
            className={classes.button}
          >
            CARD (coming soon)
          </Button> */}
          <Button 
            variant="contained" 
            color="secondary" 
            fullWidth
            className={classes.button}
            onClick={addToTab}
          >
            <Typography variant="body2">
              PUT IT ON 
            </Typography>
            <LogoIcon />
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default ManageTabComponent