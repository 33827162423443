import React, { useEffect } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import TextField from '@material-ui/core/TextField';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import isUndefined from 'lodash/isUndefined';


function NewSearchBox(props) {
  const { currentRefinement, refine, history, placeholder } = props;
  
  useEffect(() => {
    if(!isUndefined(history.location.state) && !isUndefined(history.location.state.search)) {
      refine(history.location.state.search);
    }
  }, [history.location.state, refine]);

  // useEffect(() => {
  //   const location = {
  //     pathname: match.url,
  //     state: {
  //       search: currentRefinement
  //     }
  //   }
  //   if(!noSaveState && !isEqual(history.location.state, location.state)) {
  //     console.log(history.location.state);
  //     console.log(location.state);
  //     history.push(location);
  //   }
  // }, [currentRefinement, history, match.url, noSaveState]);

  return <TextField
    fullWidth
    placeholder={(placeholder) ? placeholder : "Search by name, sku, or vendor"}
    InputProps={{
      disableUnderline: true
    }}
    type="search"
    value={currentRefinement}
    onChange={event => {
      // this.setState({searchQuery: event.currentTarget.value});
      refine(event.currentTarget.value);
    }}
  />
};

const SearchBox = compose(connectSearchBox, withRouter)(NewSearchBox)

export default SearchBox;