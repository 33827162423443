import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import DefaultSchema from '../Schema';
import isUndefined from 'lodash/isUndefined';
import { Typography } from '@material-ui/core';
import { TableView } from './InfiniteHitsComponent';
import { withRouter } from 'react-router-dom';
import reject from 'lodash/reject';
import orderBy from 'lodash/orderBy';
import RelationshipsWriteComponent from './RelationshipsWriteComponent';
import Grid from '@material-ui/core/Grid';
// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
import size from 'lodash/size';
import EditOptionsComponent from './EditOptionsComponent';

function RelationshipsComponent(props) {
  const [relationRef, setRelationRef] = useState();
  const [relationCollection, setRelationCollection] = useState();
  const [hits, setHits] = useState([]);
  const [schema, setSchema] = useState(undefined);
  const [querySize, setQuerySize] = useState(0);
  const [assetType, setAssetType] = useState(false);
  const { values } = props;

  useEffect(() => {
    switch (props.params.assetType) {
      case "stock":
        setRelationRef(
          firebase.firestore()
          .collection('quantity')
          .doc(props.params.merchantId)
          .collection('items')
          .where('selectStock-id','==', props.params.itemId)
          .where('qty', '>', 0)
          .orderBy('qty', 'desc')
          .orderBy('createdAt', 'desc')
          .limit(10)
        )
        setRelationCollection(
          firebase.firestore()
          .collection('quantity')
          .doc(props.params.merchantId)
          .collection('items')
        )
        setAssetType('quantity');
        break;
      case "attribute":
        setRelationRef(
          firebase.firestore()
            .collection('attribute')
            .doc(props.params.merchantId)
            .collection('items')
            .doc(props.params.itemId)
            .collection('options')
        )
        setRelationCollection(
          firebase.firestore()
            .collection('attribute')
            .doc(props.params.merchantId)
            .collection('items')
            .doc(props.params.itemId)
            .collection('options')
        )
        setAssetType('attribute');
        break;
      default:
        setAssetType(false);
        break;
    }
    
  }, [props.params.assetType, props.params.itemId, props.params.merchantId]);

  useEffect(() => {
    if(assetType) {
      setSchema(DefaultSchema(props.params.merchantId, assetType));
    }
  }, [assetType, props.params.merchantId])

  useEffect(() => {
    function handleData(query) {
      const data = [];
      if(!query.empty) {
        query.forEach((doc) => {
          data.push({id: doc.id, ...doc.data()});
        });
      }
      setQuerySize(query.size);
      setHits(data);
    }
    let unsub = () => {};
    if(!isUndefined(schema)) {
      schema.schemaRef.get().then((query) => {
        let newSchema = schema;
        const data = [];
        if(!query.empty) {
          query.forEach(function(doc) {
              data.push({...doc.data(), fieldId: doc.id });
          });
        };
        newSchema.properties = [...schema.default, ...data];
        newSchema.properties = reject(newSchema.properties, function(o) {
          return o.fieldId === 'selectStock';
        });
        newSchema.properties = orderBy(newSchema.properties, 'priority', 'asc');
        setSchema(newSchema);
        return;
      }).then(() => {
        unsub = relationRef.onSnapshot(handleData);
      });
      return () => {
        unsub();
      };
    }
  }, [schema, relationRef]);

  if(!assetType || isUndefined(schema)) {
    return <div />
  }

  if(assetType === 'attribute') {
    return <EditOptionsComponent relationRef={relationRef} />
  }

  return (
    <div>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Typography>
            {`Showing ${querySize} of ${size(values.quantity)} ${props.params.assetType} item(s)`}
          </Typography>
        </Grid>
      </Grid>
      {(!isUndefined(props.params.itemId)) && <RelationshipsWriteComponent 
        merchantId={props.params.merchantId}
        schema={schema}
        stockId={props.params.itemId}
        stockTitle={`${values.name} - ${values.sku}`}
      />}
      <TableView 
        hits={hits}
        schema={schema} 
        selectedItem={() => {}} 
        value={{id: undefined}}
        hideAction={true}
        customAction={true}
        history={props.history}
        match={props.match}
        relationCollection={relationCollection}
        customActionHeader={`Actions`}
      />
    </div>
  )
}

export default withRouter(RelationshipsComponent);