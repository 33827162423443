import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { InstantSearch, Configure, Stats } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import SearchBox from './SearchBoxComponent';
import { TableView, InfiniteHits} from './InfiniteHitsComponent';
import { compose } from 'recompose';
import firebase from 'firebase/app';
import 'firebase/firestore';
import DefaultSchema from '../Schema';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { Typography } from '@material-ui/core';
// import IconButton from '@material-ui/core/IconButton';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TablePagination from '@material-ui/core/TablePagination';
import isUndefined from 'lodash/isUndefined';
import { CSVLink } from "react-csv";
import forEach from 'lodash/forEach';
import orderBy from 'lodash/orderBy';
import uniqueId from 'lodash/uniqueId';
import isObject from 'lodash/isObject';

const algoliaClient = algoliasearch(
  'IC1SHLYR5C',
  'fd74929dbaf31ec517735d514d9dd8e2'
);

// let firstLoad = false;

const searchClient = {
  search(requests) {
    // if (firstLoad && requests[0].params.query !== '') {
    //   firstLoad = false;
    // }
    // if (firstLoad && requests[0].params.query === '') {
    //   return;
    // }
    algoliaClient.clearCache();
    return algoliaClient.search(requests);
  },
};

const styles = theme => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addItem: {
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
    padding: '10px 20px',
  },
  contentWrapper: {
    margin: 0,
    textAlign: 'center',
    // overflow: 'scroll',
    maxWidth: '100%'
  },
  mainContent: {
    flex: 1,
    padding: '48px 36px 0'
  },
});

class ListComponent extends React.Component {
  constructor(props) {
    super(props);
    const merchantId = props.match.params.merchantId;
    const assetType = props.match.params.assetType;
    this.state = {
      open: false,
      data: [],
      size: 0,
      keyToSortby: "createdAt",
      loading: true,
      lastVisible: '',
      unsubscribe: () => {},
      page: 0,
      rowsPerPage: 9,
      stateOfSearch: {
        query: '',
        refinementList: {
          asset: assetType,
          merchantId: merchantId
        },
      },
      assetType: assetType,
      merchantId: merchantId,
      schema: DefaultSchema(merchantId, assetType),
      firebaseRef: {
        stock: firebase.firestore()
          .collection('stock')
          .doc(merchantId)
          .collection('items'),
        attribute: firebase.firestore()
          .collection('attribute')
          .doc(merchantId)
          .collection('items'),
        product: firebase.firestore()
          .collection('product')
          .doc(merchantId)
          .collection('items'),
        quantity: firebase.firestore()
          .collection('quantity')
          .doc(merchantId)
          .collection('items')
      }
    }
    this.orderData = this.orderData.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.exportHeadersToCSV = this.exportHeadersToCSV.bind(this);
    this.exportDataToCSV = this.exportDataToCSV.bind(this);
  }

  componentWillUnmount() {
    this.state.unsubscribe();
  }

  componentDidMount() {
    if(isUndefined(this.props.history.location.state)) {
      const location = {
        pathname: this.props.match.url,
        state: {
          page: 0,
          rowsPerPage: 9
        }
      }
      this.props.history.replace(location);
    }
    let unsubscribe;
    this.state.schema.schemaRef.get().then((query) => {
      let newSchema = this.state.schema;
      const data = [];
      if(!query.empty) {
        query.forEach(function(doc) {
            data.push({...doc.data(), fieldId: doc.id });
        });
      };
      newSchema.properties = [...newSchema.default, ...data];
      if(newSchema.asset === 'stock') {
        newSchema.properties.push({
          type: {
            label: 'Number',
            value: 'number'
          },
          fieldId: "quantityNum",
          fieldName: "Quantity",
          required: true,
          unique: false,
          priority: 298,
          disabled: true
        })
        newSchema.properties = orderBy(newSchema.properties, 'priority', 'desc');
      }
      this.setState({
        schema: newSchema,
      })
      return;
    })
    .then(() => {
      let ref;
      console.log(this.state.schema.asset);
      if(this.state.schema.asset === 'stock') {
        ref = this.state.schema.dataRef.collection('items').orderBy('quantity', 'desc');

      } else if(this.state.schema.asset === 'transaction') {
        ref = this.state.schema.dataRef.where('merchant_id','==',this.state.merchantId).orderBy('created_at', 'desc');
      } else {
        ref = this.state.schema.dataRef.collection('items');
      }
      unsubscribe = ref.onSnapshot(this.handleData);
      this.setState({
        unsubscribe: unsubscribe
      })
    })
    .catch((error) => {
      console.log(error);
    });
  }

  handleSearchChange(searchState) {
    this.setState({
      stateOfSearch: searchState
    })
  }

  handleData(query) {
    let data = []
    if(!query.empty) {
      query.forEach((doc) => {
        let currentItem = {
          id: doc.id,
          ...doc.data()
        };

        if(this.state.schema.asset === 'stock') {
          if(isUndefined(currentItem.quantity)) {
            currentItem.quantityNum = 0;
          } else {
            let count = 0;
            forEach(currentItem.quantity, (item) => {
              count = count + item.qty;
            });
            currentItem.quantityNum = count;
          }
        };
        data.push(currentItem);
      })
    }
    this.setState({
      size: query.size,
      data: data,
      loading: false,
      lastVisible: query.docs[query.docs.length-1]
    });
  }

  handleChangePage = (event, page) => {
    let newUrlState = this.props.location.state;
    if(isUndefined(newUrlState)) {
      newUrlState = {};
    }

    if(newUrlState.page !== page) {
      newUrlState.page = page;
      const location = {
        pathname: this.props.match.url,
        state: newUrlState
      }
      this.props.history.replace(location);
    }
  };

  handleChangeRowsPerPage = event => {
    let newUrlState = this.props.location.state;
    if(isUndefined(newUrlState)) {
      newUrlState = {};
    }

    if(newUrlState.rowsPerPage !== event.target.value) {
      newUrlState.rowsPerPage = event.target.value;
      const location = {
        pathname: this.props.match.url,
        state: newUrlState
      }
      this.props.history.replace(location);
    }
  };

  exportHeadersToCSV = () => {
    const headers = [{key: 'cost', label: 'Cost'}, {key: 'price', label: 'Price'}];
    forEach(this.state.schema.properties, (data, key) => {
      headers.push({key: data.fieldId, label: data.fieldName})
    });
    return headers;
  }

  exportDataToCSV = () => {
    const data = [];
    forEach(this.state.data, (item) => {
      let newItem = {...item};
      forEach(item, (field, key) => {
        if(key === 'cost') {
          newItem.cost = field.formatted;
        } else if(key === 'price') {
          newItem.price = field.formatted;
        } else if(isObject(field) && !field.length) {
          newItem[key] = field.value;
        } else if(isObject(field) && field.length) {
          let text = '';
          if(field.length < 2 && field[0]) {
            text = field[0].label;
          } else {
            forEach(field, (child, key) => {
              if(key === 0) {
                text = `${child.label}`
              } else {
                text = `${text}, ${child.label}`
              }
            })
          }
          newItem[key] = text;
        }
      })
      data.push(newItem);
    })
    return data;
  }

  orderData(key, direction, data) {
    let dataToSort = this.state.data;
    if(data) {
      dataToSort = data;
      const newData = orderBy(dataToSort, [key], [direction]);
      return newData;
    }
    this.setState({
      data: orderBy(dataToSort, [key], [direction])
    })
    return;
  }


  render() {
    const { classes, match, history, location} = this.props;
    const { schema, assetType, merchantId } = this.state;

    return (
      <InstantSearch
        indexName="opel_merchant"
        searchClient={searchClient}
        searchState={this.state.stateOfSearch}
        onSearchStateChange={this.handleSearchChange}
      >
        <Configure 
          filters={`asset:${assetType} AND merchantId:${merchantId}`}
          hitsPerPage={9}
        />
        {/* <Paper className={classes.paper}> */}
          <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            <Toolbar disableGutters>
              <Grid container spacing={4} alignItems="center">
                <Grid item>
                  <SearchIcon className={classes.block} color="inherit" />
                </Grid>
                <Grid item xs>
                  <SearchBox />
                </Grid>
                {(this.state.stateOfSearch.query !== '') && <Stats
                  translations={{
                    stats(nbHits, timeSpentMS) {
                      return `${nbHits} results found in ${timeSpentMS}ms`;
                    },
                  }}
                />}
                {(this.state.stateOfSearch.query === '') && <Grid item>
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[9, 25, 50, 100, 250]}
                    count={this.state.data.length}
                    rowsPerPage={(!isUndefined(location.state) && !isUndefined(location.state.rowsPerPage)) ? location.state.rowsPerPage : 9 }
                    page={(!isUndefined(location.state) && !isUndefined(location.state.page)) ? location.state.page : 0 }
                    backIconButtonProps={{
                      'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </Grid>}
                <Grid item>
                  <CSVLink 
                    data={this.exportDataToCSV()}
                    headers={this.exportHeadersToCSV()}
                    filename={uniqueId('export_')}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.addItem}
                      onClick={this.exportDataToCSV}
                    >
                      Export
                    </Button>
                  </CSVLink>
                  {schema.canAdd && <Link to={`${match.url}/add`}>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        className={classes.addItem}
                      >
                        Add
                      </Button>
                    </Link>}
                </Grid>
                {(this.props.match.params.assetType === 'quantity') && <Grid item>
                  <Link to={`/${merchantId}/stock`}>
                    <Button 
                      variant="outlined" 
                      color="primary" 
                      className={classes.addItem}
                    >
                      Back
                    </Button>
                  </Link>
                </Grid>}
              </Grid>
            </Toolbar>
          </AppBar>
          <div className={classes.contentWrapper}>
            {(()=> {
              if(this.state.loading) {
                return <CircularProgress />;
              } else if(this.state.stateOfSearch.query === '') {
                const page = (isUndefined(location.state) || isUndefined(location.state.page)) ? 0 : location.state.page;
                const rowsPerPage = (isUndefined(location.state) || isUndefined(location.state.rowsPerPage)) ? 9 : location.state.rowsPerPage;
                return <TableView 
                  hits={this.state.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} 
                  hasMore={false} 
                  classes={classes} 
                  schema={schema} 
                  history={history} 
                  match={match}
                  orderData={this.orderData}
                  query={this.state.stateOfSearch.query}
                />
              } else {
                return <InfiniteHits 
                  query={this.state.stateOfSearch.query}
                  canSort={false} 
                  classes={classes} 
                  schema={schema} 
                  history={history} 
                  match={match}
                  orderData={this.orderData}
                  hideAction={(schema.asset === 'transaction')}
                />
              }
            })()}
          </div>
        {/* </Paper> */}
      </InstantSearch>
    );
  }
}

ListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose (
  withStyles(styles)
)(ListComponent);
