import React, {useState, useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import isUndefined from 'lodash/isUndefined';
import findIndex from 'lodash/findIndex';
import reject from 'lodash/reject';
import forEach from 'lodash/forEach';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import ArchiveIcon from '@material-ui/icons/Archive';
import FormatListValues from './FormatListValues';
import QuickViewComponent from './QuickViewComponent';
import { Link } from 'react-router-dom';


// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import { Link } from 'react-router-dom';

const TableView = (props) => {
  const { hits, hasMore, refine, schema, history, match, hideAction, customAction, customActionHeader, orderData, relationCollection, query, hideHeader } = props;

  const [deletedKeys, setDeletedKeys] = useState([]);
  const [order, setOrder] = useState('asc');
  const [sortKey, setSortKey] = useState('');
  const [data, setData] = useState(hits);
  const [quickViewItems] = useState([]);

  function sortByKey(column) {
    if(hideAction) {
      return;
    }
    let newOrder = 'asc';
    if(sortKey === column.fieldId) {
      if(order === 'asc') {
        newOrder = 'desc';
      }
      if(order === 'desc') {
        newOrder = 'asc';
      }
      setOrder(newOrder);
    }
    setSortKey(column.fieldId);
    let combinedSortKey = column.fieldId;
    if(column.type.value === 'dollar') {
      combinedSortKey = combinedSortKey + '.unformatted';
    }
    if(refine) {
      let newData = orderData(combinedSortKey, newOrder, hits);
      setData(newData);
    } else {
      orderData(combinedSortKey, newOrder)
    }
  }

  useEffect(() => {
    let newData = hits;
    if(!isUndefined(history.location['state']) && !isUndefined(history.location['state']['deletedKeys'])) {
      newData = reject(hits, function(o) { 
        return (findIndex(history.location['state']['deletedKeys'], function(x) {return x === o.id} ) !== -1);
      });
    } else if (deletedKeys.length > 0 ) {
      newData = reject(hits, function(o) { 
        return (findIndex(deletedKeys, function(x) {return x === o.id} ) !== -1);
      });
    }

    if(query !== '') {
      // We're searching
      const nonParsed = newData;
      newData = [];
      forEach(nonParsed, (o) => {
        if(schema.asset === 'stock') {
          if(isUndefined(o.quantity)) {
            o.quantityNum = 0;
          } else {
            let count = 0;
            forEach(o.quantity, (item) => {
              count = count + item.qty;
            });
            o.quantityNum = count;
          }
        };
        newData.push(o);
      })
    }

    // if(hits.length < 4 && !isUndefined(hits[0]) && !isUndefined(hits[0].quantity)) {
    //   const x = [];
    //   forEach(hits[0].quantity, (o) => {
    //     x.push(o);
    //   })
    //   setQuickViewItems(x);
    // } else {
    //   setQuickViewItems([]);
    // }
    
    console.log('1');

    setData(newData);
  }, [hits, deletedKeys, history.location, schema.asset, query]);

  return(
    <div>
      {(quickViewItems.length > 0) && <QuickViewComponent query={query} items={quickViewItems} />}
      {(() => {
        let schemaFields;
        if(refine !== '') {
          schemaFields = schema.properties;
        } else {
          schemaFields = schema.default;
        }
        
        if(hits.length > 0) {
          return (
            <div style={{maxWidth: '100%', overflow: 'scroll'}}>
              <Table>
                {(!hideHeader) && <TableHead>
                  <TableRow>
                    {customActionHeader && <TableCell  
                      key={`actions`}
                      style={{whiteSpace: 'nowrap', maxWidth: 500, overflow: 'hidden', textOverflow: 'ellipsis'}}
                    >{customActionHeader}</TableCell>}
                    {!hideAction && <TableCell style={{whiteSpace: 'nowrap', maxWidth: 500, overflow: 'hidden', textOverflow: 'ellipsis'}} key={`actions`}>Actions</TableCell>}
                    {schemaFields.map(row => {
                      return (
                        <TableCell style={{whiteSpace: 'nowrap', maxWidth: 500, overflow: 'hidden', textOverflow: 'ellipsis'}} variant="head" key={row.fieldId}>
                          <Tooltip
                            title="Sort"
                            placement={'bottom-end'}
                          >
                            <TableSortLabel
                              active={(sortKey === row.fieldId)}
                              onClick={() => sortByKey(row)}
                              direction={order}
                            >
                              {row.fieldName}
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>}
                <TableBody>
                  {data.map(row => (
                    <TableRow 
                      hover={!hideAction ? true : false}
                      key={row.id}
                      style={{
                        cursor: !hideAction && 'pointer'
                      }}
                    >
                      <TableCell style={{whiteSpace: 'nowrap', maxWidth: 500, overflow: 'hidden', textOverflow: 'ellipsis'}} padding="checkbox">
                        {(customAction || (schema.asset === "quantity")) && <IconButton 
                          style={{
                            padding: 20
                          }}
                          onClick={(e) => {
                            if(schema.asset === 'quantity' && row.qty > 1) {
                              schema.dataRef.collection('items').doc(row.id).update({qty: row.qty - 1});
                              return;
                            }
                            if(isUndefined(relationCollection)) {
                              let newLocation = history.location;
                              if(isUndefined(history.location['state'])) {
                                newLocation['state'] = {};
                              }
                              if(isUndefined(newLocation['state']['deletedKeys'])) {
                                newLocation['state']['deletedKeys'] = []
                              }
                              setDeletedKeys([row.id, ...deletedKeys]);
                              newLocation['state']['deletedKeys'].push(row.id);
                              history.replace(newLocation);
                              schema.dataRef.collection('items').doc(row.id).delete();
                              return;
                            }
                            relationCollection.doc(row.id).delete();
                          }
                        }>
                          <ArchiveIcon />
                        </IconButton>}
                        {!hideAction && <IconButton 
                          style={{
                            padding: 20,
                            margin: 10
                          }}
                          onClick={() => history.push(match.url + '/item/' + row.id)}>
                          <EditIcon />
                        </IconButton>}
                      </TableCell>
                      {schemaFields.map(field => (
                        <TableCell 
                          style={{whiteSpace: 'nowrap', maxWidth: 500, overflow: 'hidden', textOverflow: 'ellipsis'}} 
                          key={field.fieldId}
                          onClick={() => {
                            if(hideAction) {
                              return;
                            }
                            history.push(match.url + '/item/' + row.id)
                          }}
                        >
                          <FormatListValues 
                            field={field} 
                            row={row}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )
        }

        return (
          <div align="center" style={{margin: 50}}>
            <Typography color="textSecondary" align="center">No items were found.</Typography>
            <br />
            <br />
            {(schema.asset === 'stock') && <React.Fragment>
              {/* { 
                Could call this supply
              } */}
              <Link to={`/${props.match.params.merchantId}/quantity`}>
                <Button variant="contained" color="primary">Check Quantity</Button>
              </Link>
              <br />
              <br />
            </React.Fragment>}
            {(schema.asset !== 'stock') && <Button variant="contained" href="/onboarding" color="primary">
              Accept Payments
            </Button>}
          </div>
        )
      })()}
        {hasMore && <div
          style={{
            marginTop: 20,
            textAlign: 'center'
          }}
        >
          <Button 
            variant="contained" 
            color="primary" 
            style={{
              paddingLeft: 50,
              paddingRight: 50
            }}
            onClick={refine}
          >
            Load More
          </Button>
        </div>}
    </div>
  )
};

const InfiniteHits = connectInfiniteHits(TableView);

export { InfiniteHits, TableView };