import firebase from 'firebase/app';
import 'firebase/firestore';

function product(merchantId) {
  return {
    title: 'Product',
    asset: 'product',
    canAdd: true,
    canEdit: true,
    canDelete: false,
    properties: [],
    default: [
      {
        type: {
          label: 'Select Stock Items',
          value: 'select-stock'
        },
        fieldId: "select-stock",
        fieldName: "Select Stock",
        required: true,
        unique: false,
        multi: true,
        priority: 0
      }
    ],
    schemaRef: firebase.firestore()
      .collection('attribute')
      .doc(merchantId)
      .collection('items')
      .where("fieldItem", "array-contains", {label: "Product", value: "product"}),
    dataRef:firebase.firestore()
      .collection('product')
      .doc(merchantId)
  }
}

export default product;