import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import DefaultSchema from '../Schema';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import searchObject from "search-object";
import {TableView} from "./InfiniteHitsComponent";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.secondary.main,
    padding: 20
  },
  paper: {
    padding: 20,
    minHeight: 90,
    minWidth: '50%'
  },
  divider: {
    backgroundColor: 'rgba(224, 224, 224, 1)'
  }
})

function QuickViewComponent(props) {
  const [schema, setSchema] = useState(DefaultSchema(props.match.params.merchantId, 'quantity'));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { items, classes, query } = props;

  useEffect(() => {
    let newSchema = DefaultSchema(props.match.params.merchantId, 'quantity');
    newSchema.schemaRef.get().then((query) => {
      const data = [];
      if(!query.empty) {
        query.forEach(function(doc) {
            data.push({...doc.data(), fieldId: doc.id });
        });
      };
      newSchema.properties = [...newSchema.default, ...data];
      if(newSchema.asset === 'stock') {
        newSchema.properties.push({
          type: {
            label: 'Number',
            value: 'number'
          },
          fieldId: "quantityNum",
          fieldName: "Quantity",
          required: true,
          unique: false,
          priority: 0,
          disabled: true
        })
        newSchema.properties = orderBy(newSchema.properties, 'priority', 'asc');
      }
      setSchema(newSchema);
      setLoading(false);
      return;
    });
  }, [props.match.params.merchantId]);

  useEffect(() => {
    let x = filter(items, (o) => {
      return searchObject(o, query);
    });
    if(x.length > 5) {
      x = x.splice(1, 3);
    }
    setData(x);
  }, [items, query])

  if(loading) {
    return <div className={classes.root} />
  }

  if(data.length < 1) {
    return <div className={classes.root} />
  }

  return (
    <div className={classes.root}>
      <Paper square={true} className={classes.paper}>
        <Grid container direction="column" alignContent="flex-start">
          {/* <Grid item>
            <Typography gutterBottom align="left" variant="h6">Quick View</Typography>
          </Grid> */}
          <Divider className={classes.divider} light={true} />
          <Grid item>
            <TableView 
              hits={data} 
              schema={schema} 
              selectedItem={() => {}} 
              value={{id: undefined}}
              hideAction={true}
              customAction={true}
              history={props.history}
              match={props.match}
              hideHeader={true}
              // relationCollection={relationCollection}
              customActionHeader={`Actions`}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default compose(
  withRouter,
  withStyles(styles)
)(QuickViewComponent);