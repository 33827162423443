import React, { Component } from 'react';
import './App.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Paperbase from './theme/Paperbase';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import Auth from './Routes/auth';
import ErrorBoundary from './Routes/ErrorBoundary';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { Button } from '@material-ui/core';



const config = {
  apiKey: "AIzaSyAQYlmJMcqDUNxvjMqUuvxxf7ChNr9xy5M",
  authDomain: "opel-f1905.firebaseapp.com",
  databaseURL: "https://opel-f1905.firebaseio.com",
  projectId: "opel-f1905",
  storageBucket: "opel-f1905.appspot.com",
  messagingSenderId: "349724861359",
  appId: "1:349724861359:web:a038d9f395332626"
};

firebase.initializeApp(config);

let theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontSize: 16,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#dff2f5',
      main: '#154f5a',
      dark: '#061f1d',
    },
    secondary: {
      main: '#f6ad1c'
    }
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: theme.palette.primary.main,
      },
    },
    MuiButton: {
      label: {
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 0,
        },
      },
      labelContainer: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiInputBase: {
      input: {
        height: 'auto'
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

class App extends Component {
  constructor(props) {
    super(props);
    this._authStateChanged = this._authStateChanged.bind(this);
    this.state = {
      isSignedIn: undefined,
      loading: true
    }
  }

  componentDidMount() {
    this.authSubscription = firebase.auth().onAuthStateChanged(this._authStateChanged);
  }

  componentWillUnmount() {
    this.authSubscription();
  }

  _authStateChanged(user) {
    if(!isUndefined(user) && !isNull(user)) {
      firebase.firestore().collection('users').doc(user.uid).get().then((doc) => {
        const userData = {
          ...user
        }
        if(doc.exists) {
          window.Rollbar.configure({
            payload: {
              person: {
                id: userData.uid, // required
                email: userData.email,
                username: userData.displayName
              }
            }
          });
          userData.merchantId = doc.data().merchant_id;
          this.setState({
            userData: userData,
            isSignedIn: true,
            loading: false
          });
        }
      });
    } else {
      this.setState({
        isSignedIn: false,
        loading: false
      });
    }
  }

  render() {
    const { isSignedIn, loading, userData } = this.state;

    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <ErrorBoundary>
            {(() => {
              if(isSignedIn && !loading && (isUndefined(userData) || isUndefined(userData.merchantId))) {
                return (
                  <div
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    <p>Oops! There seems to be an issue. Try logging in again and see if the problem persists.</p>
                    <Button 
                      variant="contained"
                      onClick={() => {
                        firebase.auth().signOut();
                      }}
                    >Logout</Button>
                  </div>
                )
              }
              if(isSignedIn && !loading) {
                return (
                  <SnackbarProvider maxSnack={3}>
                    <Paperbase userData={this.state.userData}  />
                  </SnackbarProvider>
                );
              }
              if(!isSignedIn && !loading) {
                return (
                  <SnackbarProvider maxSnack={3}>
                    <Auth />
                  </SnackbarProvider>
                );
              }

              return (
                <div style={{
                  width: '100%',
                  height: '100vh',
                  backgroundColor: '#154f5a'
                }}>
                  <CircularProgress
                    size={100}
                    thickness={10}
                    color="secondary"
                    style={{
                      margin: 'auto',
                      display: 'block',
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0
                    }}
                  />
                </div>
              )
            })()}
          </ErrorBoundary>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
