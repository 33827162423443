import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import HomeIcon from '@material-ui/icons/Home';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import TuneIcon from '@material-ui/icons/Tune';
import SettingsIcon from '@material-ui/icons/Settings';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from "react-router-dom";
// import isUndefined from 'lodash/isUndefined';

// import firebase from 'firebase/app';
// import 'firebase/firestore';


const categories = [
  {
    id: 'Store',
    children: [
      // { id: 'Product', href: '/stock', icon: <BusinessCenterIcon /> },
      { id: 'Register', href: '/register', icon: <AttachMoneyIcon />},
      { id: 'Stock', href: '/stock', icon: <TuneIcon /> },
      { id: 'On The Shelf', href: '/quantity', icon: <BusinessCenterIcon /> },
      // { id: 'Transaction', href: '/transaction', icon: <FormatListNumbered /> },
      { id: 'Report', href: '/report', icon: <BusinessCenterIcon /> },
      // { id: 'Quantity', href: '/quantity', icon: <FormatListNumbered /> },
      { id: 'Attribute', href: '/attribute', icon: <DnsRoundedIcon /> },
      { id: 'Settings', href: '/settings', icon:  <SettingsIcon /> },
    ],
  },
  // {
  //   id: 'Checkout',
  //   children: [
  //     { id: 'Sale', href: '/stock', icon: <AttachMoneyIcon /> },
  //     { id: 'Customer', href: '/stock', icon: <PeopleIcon /> },
  //   ],
  // },
];

const styles = theme => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 16,
    paddingBottom: 16,
    color: 'rgba(255, 255, 255, 0.7)',
  },
  itemCategory: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 16,
  },
  opel: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.dark
    },
  },
  itemActiveItem: {
    color: theme.palette.secondary.main
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  drawerPaper: {
    height: '100vh'
  }
});

function Navigator(props) {
  const { classes, merchantId, variant, toggleWidth } = props;
  // const [companyName, setCompanyName] = useState();

  // useEffect(() => {
  //   function handleCompanyName(doc) {
  //     const data = doc.data();
  //     setCompanyName(data.merchant_name);
  //   }
  //   if(!isUndefined(merchantId)) {
  //     firebase.firestore()
  //       .collection('merchants')
  //       .doc(merchantId)
  //       .get()
  //       .then(handleCompanyName)
  //   }
  // }, [merchantId])

  return (
    <Drawer 
      classes={{
        paper: classes.drawerPaper,
      }} 
      variant={variant}
    >
      <List disablePadding>
        <ListItem
          onClick={toggleWidth}
          button
          dense
          className={classNames(
            classes.item,
            classes.itemActionable
          )}
        >
          <ListItemIcon><MenuIcon /></ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary
            }}
          >
            Menu
          </ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            {children.map(({ id: childId, icon, active, href }) => (
              <Link 
                key={childId}
                to={`/${merchantId}${href}`}
                onClick={props.onClose}
              >
                <ListItem
                  button
                  dense
                  className={classNames(
                    classes.item,
                    classes.itemActionable,
                    active && classes.itemActiveItem,
                  )}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary
                    }}
                  >
                    {childId}
                  </ListItemText>
                </ListItem>
              </Link>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
      <a 
        style={{
          position: 'relative',
          height: '100%',
          overflow: 'hidden'
        }}
        href="https://opel.org" rel="noopener noreferrer" target="_blank"
      >
        <svg 
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            maxWidth: 100
          }}
          width="80%" viewBox="0 0 1110 1013" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <polygon id="path-1" points="0.13129771 0.621084112 344 0.621084112 344 467.877533 0.13129771 467.877533"></polygon>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="splash" transform="translate(-284.000000, -1445.000000)">
                    <g id="Group" transform="translate(284.000000, 1445.000000)">
                        <g id="Group-4" transform="translate(2.000000, 328.000000)" fill="#0E1917">
                            <path d="M1286.95628,159.045378 L1286.95628,478.405065 C1286.95628,483.953364 1286.83388,489.624179 1286.57158,495.435015 C1277.70597,664.369315 1297.12463,868.361158 1126.125,868.361158 L767.593204,856.258167 L0.457269701,409.558912 L1053.74874,0.0175025176 L1286.95628,159.045378" id="Fill-3"></path>
                        </g>
                        <polyline id="Fill-5" fill="#EBA72D" points="1069 587.213669 0 740 0 306.141421 1069 0 1069 587.213669"></polyline>
                        <path d="M208.128083,556.501689 C198.695065,577.339526 186.663715,588.987029 172.102066,591.592852 C157.065931,594.338585 145.305841,586.661026 136.829644,568.638876 C128.42148,550.677937 124.184254,526.491005 124.184254,495.850727 C124.184254,474.951679 126.3709,455.967648 130.677903,438.767469 C135.052067,421.497335 140.931676,407.515085 148.246953,396.820719 C155.628519,386.065141 163.83084,379.550585 172.991726,377.285792 C187.757474,373.656878 199.719919,380.58242 208.743867,398.26354 C217.835849,415.944659 222.415856,440.481366 222.415856,471.725006 C222.415856,507.227155 217.630878,535.532686 208.128083,556.501689 Z M267.464949,364.886273 C258.509906,338.084774 245.795611,318.488638 229.319447,306.220284 C213.051743,294.091841 193.978992,291.005078 172.375942,296.898784 C152.416147,302.31155 134.711903,315.060844 119.260593,335.076711 C103.950583,354.882712 92.1242036,379.82166 83.6462623,409.911043 C75.1691933,439.930471 71,472.485766 71,507.708095 C71,541.225271 74.9642225,570.894924 83.0313499,596.804497 C91.165638,622.845236 102.787046,642.721193 117.962736,656.292458 C132.318542,669.172917 149.13487,674.585683 168.343686,672.600711 C169.642416,672.530756 171.009179,672.329635 172.375942,672.11977 C185.844704,670.064843 198.421189,664.652077 210.11063,655.872728 C216.332148,651.220722 222.278918,645.598091 227.953557,639.022324 C244.495136,619.898383 257.48418,594.679615 266.849165,563.427231 C276.283927,531.964982 281,497.153638 281,459.185577 C281,423.342398 276.487154,391.888893 267.464949,364.886273 L267.464949,364.886273 Z" id="Fill-6" fill="#0E1917"></path>
                        <path d="M436.324875,412.124793 C430.043742,423.853567 422.054245,430.715775 412.359869,432.772686 L378.289295,440.046276 L378.289295,329.393177 L411.676001,321.16553 C422.054245,318.627213 430.315547,321.918272 436.461648,331.178751 C442.673959,340.369208 445.745703,353.883556 445.745703,371.511727 C445.745703,386.881672 442.606008,400.39602 436.324875,412.124793 Z M499.412367,280.552463 C490.400117,261.278763 478.314381,248.307089 463.225722,241.654949 C448.272966,235.072831 431.750363,234.250067 413.724992,239.116632 L318,264.981204 L318,642 L378.289295,632.538206 L378.289295,520.782251 L410.993875,514.541493 C428.745699,511.180412 445.336254,503.022788 460.698459,490.051114 C476.268003,477.018171 488.898221,459.04864 498.457565,436.273813 C508.152812,413.358941 513,386.610335 513,356.080513 C513,325.139308 508.492568,299.966209 499.412367,280.552463 L499.412367,280.552463 Z" id="Fill-7" fill="#0E1917"></path>
                        <polyline id="Fill-8" fill="#0E1917" points="738.955686 487.299006 619.209321 508.932614 619.209321 432.267371 709.001861 414.197151 702.094541 327.400706 619.209321 346.021155 619.209321 271.207476 733.621622 243.137062 726.646241 156 550 203.572976 550 606 746 575.326916 738.955686 487.299006"></polyline>
                        <g id="Group-12" transform="translate(789.000000, 101.000000)">
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1"></use>
                            </mask>
                            <g id="Clip-10"></g>
                            <polyline id="Fill-9" fill="#0E1917" mask="url(#mask-2)" points="211.905751 339.400598 79.2950636 363.255477 79.2950636 16.6205607 0.13129771 37.9473645 0.13129771 467.877533 219.932417 433.525458 211.905751 339.400598"></polyline>
                            <polyline id="Fill-11" fill="#DFF1F1" mask="url(#mask-2)" points="346.127023 282.55828 165.006209 245.039551 165.006209 0.621084112 346.127023 50.2728224 346.127023 282.55828"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </a>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
