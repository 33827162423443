import React from 'react';
import { Route, Link } from "react-router-dom";
import Assets from '../Routes/Assets';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  hero: {
    background: theme.palette.secondary.main,
    padding: 20,
  },
  title: {
    marginLeft: 20,
  },
  button: {
    marginRight: 10,
  }
})

function CheckList(props) {
  const { classes } = props;

  

  return (
    <div>
      <div className={classes.hero}>
        <Grid spacing={8} container direction="row" justify="space-between">
          <Grid item>
            <Typography className={classes.title} variant="h4">
              {props.match.params.checkListType}
            </Typography>
          </Grid>
          <Grid item>
            <Link to={`/${props.match.params.merchantId}/checklist/open`}>
              <Button className={classes.button}>
                Open
              </Button>
            </Link>
            <Link to={`/${props.match.params.merchantId}/checklist/close`}>
              <Button className={classes.button}>
                Close
              </Button>
            </Link>
          </Grid>
        </Grid>
      </div>
      <Route path={`/:merchantId/checklist/:assetType(open)`} component={Assets}/>
      <Route path={`/:merchantId/checklist/:assetType(close)`} component={Assets}/>
    </div>
  )
}

export default withStyles(styles)(CheckList);