import isUndefined from 'lodash/isUndefined';

function InitialValues(schema) {
  const fields = schema.properties;
  // We'll eventually need an if statement here to determine if
  // we're fetching data
  let values = {};
  for (var i = 0; i < fields.length; i++) {
    const field = fields[i];
    let newValues = values;
    if(field.type.value === 'checkbox') {
      values[field.fieldId] = false;
    }
    if(field.type.value === 'number' && isUndefined(newValues[field.fieldId])) {
      values[field.fieldId] = 1;
    }
    if(field.type.value === 'select-stock') {
      values[field.fieldId] = '';
    }
    if(field.fieldId === 'qty') {
      values[field.fieldId] = 1;
    }
  }
  return values;
}

export default InitialValues;