import firebase from 'firebase/app';
import 'firebase/firestore';

function quantity(merchantId) {
  return {
    title: 'Quantity',
    asset: 'quantity',
    canAdd: true,
    canEdit: true,
    canDelete: true,
    properties: [],
    default: [
      {
        type: {
          label: 'Select Stock Items',
          value: 'select-stock'
        },
        fieldId: "selectStock",
        fieldName: "Select Stock Items",
        required: true,
        unique: false,
        priority: 2
      },
      {
        type: {
          label: 'Number',
          value: 'number'
        },
        fieldId: "qty",
        fieldName: "QTY",
        required: true,
        unique: false,
        priority: 2,
        disabled: false
      }
    ],
    schemaRef: firebase.firestore()
      .collection('attribute')
      .doc(merchantId)
      .collection('items')
      .where("fieldItem", "array-contains", {label: "Quantity", value: "quantity"}),
    dataRef:firebase.firestore()
      .collection('quantity')
      .doc(merchantId),
  }

}

export default quantity;