import React, {useEffect, useState} from 'react';
import { compose } from 'recompose';
import ListComponent from "../components/ListComponent";
import WriteComponent from "../components/WriteComponent";
import { Route } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

const styles = () => ({
  mainContent: {
    flex: 1,
    // padding: '48px 36px 0'
  }
});


function Assets(props) {
  const { classes, } = props;
  const [path, setPath] = useState('/:merchantId/:assetType')

  // useEffect(() => {
  //   function handleSchemaQuery(query) {
  //     const data = [];
  //     if(!query.empty) {
  //       query.forEach(function(doc) {
  //           data.push({...doc.data(), fieldId: doc.id });
  //       });
  //     };
  //     let newSchema = schema
  //     newSchema.properties = [...data, ...schema.default];
  //     setSchema(newSchema);
  //   }

  //   schema.schemaRef.onSnapshot(handleSchemaQuery);
  // }, [schema.schemaRef])


  // function onAdd(data) {
  //   return schema.dataRef.collection('items').add(data).then((data) => {
  //     props.enqueueSnackbar("Successfully added item.");
  //     return goToAsset(data.id);
  //   });
  // }

  useEffect(() => {
    if(props.match.params.assetType === 'open' || props.match.params.assetType === 'close') {
      setPath(`/:merchantId/checklist/:assetType`);
    }
  }, [props.match.params.assetType])

  return (
    <div>
      <main className={classes.mainContent}>
        {/* here's a nested Route,
        match.url helps us make a relative path */}
        <Route 
          exact
          path={path}
          component={ListComponent}
        />
        <Route 
          exact
          path={`${path}/page/:pageNumber`}
          component={ListComponent}
        />
        <Route 
          exact 
          path={`${path}/item/:itemId`}
          component={WriteComponent}
        />
        <Route 
          exact 
          path={`${path}/add`}
          component={WriteComponent}
        />
      </main>
    </div>  
  )

}

export default compose(
  withSnackbar,
  withStyles(styles)
)(Assets);