// Import FirebaseAuth and firebase.
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as firebaseui from 'firebaseui'
import firebase from 'firebase/app';
import 'firebase/auth';


class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorText: undefined
    }
    this.uiConfig = {
      // Popup signin flow rather than redirect flow.
      tosUrl: "https://opel.org/terms/",
      privacyPolicyUrl: 'https://opel.org/privacy-policy/',
      signInFlow: 'popup',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        }
      ],
      'credentialHelper': firebaseui.auth.CredentialHelper.NONE,
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          if (authResult.additionalUserInfo.isNewUser) {
            firebase.firestore().collection('merchants').add({
              email: authResult.user.email,
              disabled: true
            })
            .then((res) => {
              return firebase.firestore().collection('users').doc(authResult.user.uid).set({
                merchant_id: res.id
              })
            })
            .then((res) => {
              window.location = "https://merchant.opel.org/onboarding";
            }) 
          }
        }
      }
    };
  }
 
  render() {
    return (
      <div style={{
        width: '100%',
        height: '100vh',
        backgroundColor: '#154f5a',
        justifyContent: 'center',
        paddingTop: 100
      }}>
        <div
          style={{
            height: '100%',
            maxHeight: 500,
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        > 
          <StyledFirebaseAuth uiCallback={ui => ui.disableAutoSignIn()} uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
        </div>
      </div>
    );
  }
}

export default Auth;
