/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import isUndefined from 'lodash/isUndefined';

const styles = theme => ({
  input: {
    display: 'flex',
    padding: 0,
    width: '100%'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing() * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class SelectComponent extends React.Component {
  constructor(props) {
    super(props); 
    this.handleCreate = this.handleCreate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      multi:  null,
      data: [],
      unsubscribe: () => {}
    }
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
    });
    this.props.onChange(name, value)
  };

  handleCreate(data) {
    this.props.firestoreRef.collection('options').add({
      label: data
    }).then(() => {
      let oldValues = this.props.value;;
      if(isUndefined(oldValues)) {
        oldValues = [];
      }
      let newValues = [{label: data, value: data}, ...oldValues];
      // React is having trouble keeping up with the arrayed value changes,
      // but if we change it to an object first it gets it's shit together
      this.props.onChange(this.props.fieldId, {label: data, value: data});
      this.props.onChange(this.props.fieldId, newValues);
    });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log(this.props.value);
  //   if(!isEqual(prevProps.value, this.props.value)) {
  //     console.log(prevProps.value);
  //     this.setState({
  //       [this.props.fieldId]: this.props.value
  //     })
  //   }
  // }

  componentWillUnmount() {
    this.state.unsubscribe()
  }

  componentDidMount() {
    let unsubscribe;
    if(!isUndefined(this.props.firestoreRef)) {
      unsubscribe = this.props.firestoreRef.collection('options').onSnapshot((querySnap) => {
        const options = [];
        querySnap.forEach((doc) => {
          options.push(doc.data());
        });
        const formattedOptions = options.map(options => ({
          value: options.label,
          label: options.label,
        }));
        this.setState({
          data: formattedOptions,
          unsubscribe: unsubscribe
        })
      });
    } else {
      this.setState({
        data: this.props.data
      })
    }
  }

  render() {
    const { classes, theme, isMulti, isCreatable, required, placeholder, fieldId, error, disabled } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div
        style={{
          marginTop: 10,
          marginBottom: 10
        }}
      >
        {(() => {
          if(!isCreatable) {
            return <Select
              isDisabled={disabled}
              autoComplete="opel"
              classes={classes}
              styles={selectStyles}
              textFieldProps={{
                label: this.props.label,
                required: required,
                error: isUndefined(error) ? false : error.error,
                helperText: isUndefined(error) ? '' : error.helperText,
                InputLabelProps: {
                  shrink: true,
                  required: required
                },
              }}
              isMulti={isMulti}
              placeholder={placeholder}
              options={this.state.data}
              components={components}
              value={isUndefined(this.props.value) ? '' : this.props.value }
              onChange={this.handleChange(fieldId)}
            />
          }

          return <Creatable
            autoComplete="opel"
            isDisabled={disabled}
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              label: this.props.label,
              required: required,
              error: isUndefined(error) ? false : error.error,
              InputLabelProps: {
                shrink: true,
                required: required
              },
            }}
            placeholder={placeholder}
            options={this.state.data}
            components={components}
            value={isUndefined(this.props.value) ? '' : this.props.value }
            onChange={this.handleChange(fieldId)}
            onCreateOption={this.handleCreate}
            isMulti={isMulti}
            required={required}
          />
        })()}
      </div>
    );
  }
}

SelectComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectComponent);