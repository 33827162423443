import React from 'react'
import { connectInfiniteHits } from 'react-instantsearch-dom';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { Button, Typography } from '@material-ui/core';
import isUndefined from 'lodash/isUndefined';

const TemplatedTabsHits = (props) => {

  function getInitials(name) {
    let nameArray = name.split(' ');
    console.log(nameArray);
    let nameInitials = '';
    if(!isUndefined(nameArray[0])) {
      nameInitials = nameInitials + nameArray[0].charAt(0);
    }
    if(!isUndefined(nameArray[1])) {
      nameInitials = nameInitials + nameArray[1].charAt(0);
    }

    return nameInitials;
  }

  if(props.hits.length > 0) {
    return (
      <Grid container spacing={0}>
          {props.hits.map((row, index) => {
            return (
              <Grid key={index} item xs={12} md={props.columns}>
                <Button 
                  onClick={() => {
                    if(props.action) {
                      props.action(row);
                    }
                  }}
                  style={{
                    position: 'relative',
                    borderRadius: 0,
                    background: 'transparent',
                    paddingTop: 30,
                    paddingBottom: 30,
                    borderBottom: '1px solid #d7d7d7'
                  }}
                  fullWidth 
                  variant="contained"
                >
                  
                    {(() => {
                        return (
                          <Grid container spacing={3} justify="flex-start" alignItems="center">
                            <Grid item>
                              <Avatar
                                style={{
                                  height: 50,
                                  width: 50
                                }}
                              >
                                {getInitials(row.display_name)}
                              </Avatar>
                            </Grid>
                            <Grid item>
                              <Grid container direction="column">
                                <Grid item>
                                  <Typography variant="h6" align="left">
                                    {row.display_name}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="subtitle2" align="left">
                                    {row.email}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                    })()}
                </Button>
              </Grid>
            )
          })}
      </Grid>
    )
  }
  
  if(props.noResults) {
    return (
      <div>
        <br />
        {props.noResults}
      </div>
    )
  }

  return (
    <div>
      <p>No results</p>
    </div>
  )
}

const InfiniteHitsTabs = connectInfiniteHits(TemplatedTabsHits);

export { InfiniteHitsTabs, TemplatedTabsHits };
