import firebase from 'firebase/app';
import 'firebase/firestore';

function stock(merchantId) {
  return {
    title: 'Stock',
    asset: 'stock',
    canAdd: true,
    canEdit: true,
    canDelete: true,
    properties: [],
    default: [
      {
        type: {
          label: 'Text',
          value: 'text'
        },
        fieldId: "name",
        fieldName: "Name",
        required: true,
        priority: 300
      },
      {
        type: {
          label: 'unique',
          value: 'unique'
        },
        fieldId: "sku",
        fieldName: "SKU",
        required: true,
        priority: 299
      },
      {
        type: {
          value: 'dollar',
          label: 'Dollar'
        },
        fieldId: "cost",
        fieldName: "Cost",
        required: true,
        priority: 297
      },
      {
        type: {
          value: 'dollar',
          label: 'Dollar'
        },
        fieldId: "price",
        fieldName: "Price",
        required: false,
        priority: 296
      }
      
    ],
    schemaRef: firebase.firestore()
      .collection('attribute')
      .doc(merchantId)
      .collection('items')
      .where("fieldItem", "array-contains", {label: "Stock", value: "stock"}),
    dataRef:firebase.firestore()
      .collection('stock')
      .doc(merchantId)
  }
  
};

export default stock;