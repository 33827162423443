import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { InstantSearch, Configure, Stats } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import SearchBox from './SearchBoxComponent';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import NumberFormat from 'react-number-format';
import { withSnackbar } from 'notistack';

import { Typography } from '@material-ui/core';
// import IconButton from '@material-ui/core/IconButton';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { InfiniteHitsTabs,  TemplatedTabsHits} from './InfiniteHitsTabs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, DialogContentText, Dialog, TextField, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const algoliaClient = algoliasearch(
  'IC1SHLYR5C',
  'fd74929dbaf31ec517735d514d9dd8e2'
);

let firstLoad = true;

const searchClient = {
  search(requests) {
    if (firstLoad && requests[0].params.query !== '') {
      firstLoad = false;
    }
    if (firstLoad && requests[0].params.query === '') {
      return;
    }
    algoliaClient.clearCache();
    return algoliaClient.search(requests);
  },
};

const styles = theme => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addItem: {
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
    padding: '10px 20px',
  },
  contentWrapper: {
    margin: 0,
    textAlign: 'center',
    overflow: 'scroll',
    maxWidth: '100%',
    minHeight: '100%',
    overflowX: 'hidden'
  },
  mainContent: {
    flex: 1,
    padding: '48px 36px 0'
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="+1 (###) ###-####" mask="_"
    />
  );
}

const TabsComponent = (props) => {
  // const { schema, assetType, merchantId } = this.state;
  const { classes, match, selectedUser, updateSelectedUser, merchant } = props;
  const [stateOfSearch, setStateOfSearch] = useState({
    query: '',
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  function handleSearchChange(searchState) {
    setStateOfSearch(searchState);
  }

  useEffect(() => {
    function handleData(query) {
      let newData = [];
      query.forEach((doc) => {
        newData.push({
          id: doc.data().user_id,
          ...doc.data()
        })
      })
      setLoading(false);
      setData(newData);
    }

    const unsubscribe = firebase.firestore()
      .collection('tabs')
      .where('merchant_id', '==', match.params.merchantId)
      .onSnapshot(handleData);

    return unsubscribe;
  }, [match.params.merchantId]);

  function userAction(row) {
    if(selectedUser.id === row.id) {
      updateSelectedUser({});
      return;
    }
    updateSelectedUser(row);
  }

  const [inviting, setInviting] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState(undefined);

  // function inviteRegulars() {
  //   setInviting(true);
  // }

  function addInvite(e) {
    e.preventDefault();
    firebase.firestore().collection('invites').doc(phone).set({
      phone: phone,
      display_name: name,
      merchant_name: merchant.merchant_name,
      merchant_id: merchant.merchant_id,
      consent_given: true
    }).then(() => {
      setInviting(false);
      props.enqueueSnackbar("Invite sent", { 
        variant: 'success',
      });
    }).catch(() => {
      props.enqueueSnackbar("Oops! Something doesn't look right.", { 
        variant: 'error',
      });
    })
  }

  return (
    <InstantSearch
      indexName="opel_members"
      searchClient={searchClient}
      searchState={stateOfSearch}
      onSearchStateChange={handleSearchChange}
      refresh={true}
    >
      <Configure 
        // filters={`asset:customers AND merchantId:${match.params.merchantId}`}
      />
      {/* <Paper className={classes.paper}> */}
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar disableGutters>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <SearchIcon className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <SearchBox noSaveState={true} placeholder={`Search Opel members`} searchAsYouType={false} />
              </Grid>
              {(stateOfSearch.query !== '') && <Grid item>
                <Stats
                  translations={{
                    stats(nbHits, timeSpentMS) {
                      return `${nbHits} results`;
                    },
                  }}
                />
              </Grid>}
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          <Dialog
            open={inviting}
            onClose={() => setInviting(false)}
            aria-labelledby="form-dialog-title"
          >
            <form onSubmit={addInvite}>
              <DialogTitle id="form-dialog-title">Invite Regulars</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Regulars want to pay with Opel. Invite them here!
                </DialogContentText>
                <br />
                <br />
                <TextField
                  required
                  autoFocus
                  label="Name"
                  type="text"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <br />
                <br />
                <TextField
                  required
                  label="Mobile Phone"
                  type="text"
                  fullWidth
                  placeholder="(___) ___-____"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  id="formatted-numberformat-input"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setInviting(false)} color="primary">
                  Cancel
                </Button>
                <Button type="submit">
                  Invite
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <Grid 
            container 
            direction="column" 
            justify="space-between"
            spacing={0}
          >
            <Grid 
              item
              style={{
                width: '100%'
              }}
            >
              {(() => {
                if(loading) {
                  return <CircularProgress />;
                }

                if(stateOfSearch.query === '') {
                  return (
                    <React.Fragment>
                      <TemplatedTabsHits 
                        hits={data}
                        columns={12} 
                        type={`members`}
                        action={userAction} 
                        selected={selectedUser}
                        selectedColor="#154f5a"
                        noResults={
                          <React.Fragment>
                            <Typography gutterBottom variant="h4">No Open Tabs</Typography>
                            <Typography variant="subtitle1">It looks like you need to search for a user to open their tab.</Typography>
                          </React.Fragment>
                        }
                      />
                    </React.Fragment>
                  )
                }

                return (
                  <React.Fragment>
                    <InfiniteHitsTabs 
                      columns={12} 
                      type={`members`} 
                      action={userAction} 
                      selected={selectedUser}
                      selectedColor="#154f5a"
                    />
                  </React.Fragment>
                )
              })()}
            </Grid>
          </Grid>
        </div>

      {/* </Paper> */}
    </InstantSearch>
  )
}


export default compose (
  withRouter,
  withStyles(styles),
  withSnackbar
)(TabsComponent);
