import React from 'react';
import firebase from 'firebase/app';
import "firebase/functions"
import "firebase/auth";
import { Button } from '@material-ui/core';

const Settings = () => {
  
  function signOut() {
    firebase.auth().signOut().then(function() {
      window.location = "https://merchant.opel.org/";
    }).catch(function(error) {
      // An error happened.
      console.log(error);
    });
  }


  return (
    <div>
      <div style={{textAlign: 'center', margin: 40}}>
        <Button fullWidth color="secondary" variant="contained" onClick={() => window.location.reload(true)} style={{padding: 20}}>Update App</Button>
      </div>
      <div style={{textAlign: 'center', margin: 40}}>
        <Button fullWidth variant="outlined" onClick={signOut} style={{padding: 20}}>Logout</Button>
      </div>
    </div>
  )
}

export default Settings
