
import isUndefined from 'lodash/isUndefined';
// import isEmpty from 'lodash/isEmpty';

function ValidateFields(field, value, errors) {
  // If the field is required let's make sure it has something in it via lodash for now.
  // If either one returns true the if statement will fail.
  let msg = '';
  let error = false;
  
  if(field.required) {
    switch(field.type.value) {
      case 'checkbox':
      case 'dollar':
      case 'number': 
        error = isUndefined(value) || value === '';
        if(error) {
          msg = 'This is a required field.';
        }  
        break;
      case 'unique':
        error = isUndefined(value) || value === '';
        if(!isUndefined(errors[field.fieldId])) {
          error = errors[field.fieldId].error;
        }
        if(error) {
          msg = 'Something is wrong';
        }
        break;
      default:
        error = isUndefined(value) || value === '';
        if(error) {
          msg = 'This is a required field.';
        }   
        break;
    }
  }
  return {
    error: error,
    helperText: msg
  };
}

export default ValidateFields;