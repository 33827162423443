import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { withStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import ActionButton from './ActionButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'recompose';
import { withSnackbar } from 'notistack';

import ValidateFields from '../helpers/ValidateFields';
import FormatFields from '../helpers/FormatFields';
import AlertComponent from './AlertComponent';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import WriteFormComponent from './WriteFormComponent';
import DefaultSchema from '../Schema';
import InitialValues from '../helpers/InitialValues';
import RelationshipsComponent from './RelationshipsComponent';
import Moment from 'react-moment';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';

const styles = theme => ({
  paper: {
    margin: 'auto',
    marginBottom: 30,
    minHeight: 300
  },
  toolbar: {
    paddingTop: 10,
    paddingBottom: 10
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  deleteButton: {
    padding: 10,
    margin: 10,
  },
  button: {
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
    padding: '10px 20px',
  },
  contentWrapper: {
    padding: theme.spacing(3),
  }
});

function WriteComponent(props) {
  const [loading, setLoading] = useState(true);
  const [success] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    text: '',
    done: '',
    cancel: ''
  });
  const [errors, setErrors] = useState({});
  const [itemExists, setItemExists] = useState(false);
  const { classes, match, history } = props;
  const [schema, setSchema] = useState(DefaultSchema(match.params.merchantId, match.params.assetType));
  const [values, setValues] = useState();
  const [deleting, setDeleting] = useState(false);
  const [disabled, setDisabled] = useState(true);

  function goBack() {
    history.goBack();
  }

  function goToItem(itemId) {
    if(schema.asset === 'open' || match.asset === 'close') {
      history.replace(`/${match.params.merchantId}/checklist/${match.params.assetType}/item/${itemId}`);
    } else {
      history.replace(`/${match.params.merchantId}/${match.params.assetType}/item/${itemId}`);
    }
  }

  useEffect(() => {
    function handleData(doc) {
      setValues({...doc.data()});
    }

    let unsubscribe = () => {};

    schema.schemaRef.get().then((query) => {
      let newSchema = schema;
      const data = [];
      if(!query.empty) {
        query.forEach(function(doc) {
            data.push({...doc.data(), fieldId: doc.id });
        });
      };
      newSchema.properties = [...schema.default, ...data];
      newSchema.properties = orderBy(newSchema.properties, 'priority', 'desc');
      setSchema(newSchema);
      setValues(InitialValues(newSchema));
      setLoading(false);
      return;
    }).then(() => {
      if(!isUndefined(match.params.itemId)) {
        if(schema.asset === 'open' || schema.asset === 'close') {
          setDisabled(false);
        }
        setItemExists(true);
        if(schema.asset  !== 'transaction') {
          unsubscribe = schema.dataRef.collection('items').doc(match.params.itemId).onSnapshot(handleData);
        } else {
          unsubscribe = schema.dataRef.doc(match.params.itemId).onSnapshot(handleData);
        }
      } else {
        setItemExists(false);
        setDisabled(false);
        setLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [match.params.itemId, schema])

  function changeError(error, fieldId)  {
    let newErrors = {};
    newErrors[fieldId] = error;
    setErrors(newErrors);
  }

  function handleFieldChange(field, fieldValue, row) {
    let newValues = values;
    const singleValue = FormatFields(field, fieldValue);
    newValues[field.fieldId] = singleValue;
    if(!isUndefined(row)) {
      newValues[`${field.fieldId}-id`] = row.id;
      // newValues['price'] = row.price;
      newValues['cost'] = row.cost;
    }
    setValues({...newValues});
  }

  function onDelete() {
    const dialogSuccess = function() {
      setDeleting(true);
      schema.dataRef.collection('items').doc(match.params.itemId).delete().then(() => {
        let newDialog = dialog;
        newDialog.open = false;
        setDialog(newDialog);
        props.enqueueSnackbar("Successfully deleted.");
        goBack();
      })
    }

    const dialogDismiss = function() {
      setDialog({
        open: false,
        dismiss: dialogDismiss,
        success: dialogSuccess,
        title: '',
        text: '',
        done: '',
        cancel: ''
      });
    }

    if(schema.asset === 'stock' && !isEmpty(values.quantity)) {
      const quantityItems = size(values.quantity);
      if(quantityItems > 0) {
        setDialog({
          open: true,
          dismiss: dialogDismiss,
          success: dialogDismiss,
          title: `You cannot delete this item`,
          text: ` Items with existing relationships cannot be deleted. `,
          cancel: 'Cancel'
        });
        return;
      }
    }

    setDialog({
      open: true,
      dismiss: dialogDismiss,
      success: dialogSuccess,
      title: 'Are you sure you want to delete this?',
      text: 'You cannot undo this.',
      done: 'Do it!',
      cancel: 'Cancel'
    });
  }

  function done(e) {
    e.preventDefault();
    setLoading(true);
    let isValid = true;
    let newErrors = {};
    forEach(schema.properties, (data) => {
      // We're expecting validateField to return true if there is an issue
      const SingleError = ValidateFields(data, values[data.fieldId], errors);
      newErrors[data.fieldId] = SingleError;
      if(isValid && isUndefined(SingleError)) {
        isValid = false;
      }
      if (isValid && SingleError.error) {
        isValid = false;
      };
    });
    setErrors(newErrors);
    if(!isValid) {
      setLoading(false);
      props.enqueueSnackbar("Oops! Something doesn't look right.", { 
        variant: 'error',
      });
      return;
    }
    
    if(itemExists) {
      schema.dataRef.collection('items').doc(match.params.itemId).update({...values, updatedAt: firebase.firestore.FieldValue.serverTimestamp()})
        .then(() => {
          setLoading(false);
          setDisabled(true);
          props.enqueueSnackbar("Successfully updated.");
        })
    } else {
      schema.dataRef.collection('items').add({...values, createdAt: firebase.firestore.FieldValue.serverTimestamp()})
        .then((data) => {
          setLoading(false);
          props.enqueueSnackbar("Successfully added.");
          goToItem(data.id);
        })
    }
  }

  function isCheckList() {
    if(schema.asset === 'open' || schema.asset === 'close') {
      return true;
    } else {
      return false
    }
  }

  return (
    <div className={classes.paper}>
      {dialog && <AlertComponent dialog={dialog} />}
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={4} alignItems="center" justify="space-between">
              <Grid item>
                <Grid container spacing={2} alignItems="center" justify="flex-start">
                  <Grid item>
                    <Typography variant="subtitle1">
                      {itemExists ? `Edit ` : `Add `}
                      {schema.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {(itemExists && schema.canDelete) && <IconButton 
                      onClick={() => onDelete()}
                      aria-label="Delete" 
                      className={classes.deleteButton}
                    >
                      <DeleteIcon />
                    </IconButton>}
                  </Grid>
                  <Grid item>
                    {(!isUndefined(values) && !isUndefined(values.createdAt) && isUndefined(values.updatedAt))  && <Typography>
                      Created at <Moment format="MM/DD/YYYY hh:mma" local>{values.createdAt.toDate()}</Moment>
                    </Typography>}
                  </Grid>
                  <Grid item>
                    {(!isUndefined(values) && !isUndefined(values.updatedAt)) && <Typography>
                      Updated at <Moment format="MM/DD/YYYY hh:mma" local>{values.createdAt.toDate()}</Moment>
                    </Typography>}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button 
                  onClick={goBack}
                  variant="outlined" 
                  color="primary" 
                  className={classes.button}
                  
                >
                  Back
                </Button>
                {((itemExists && disabled) && schema.canEdit) && <Button 
                  onClick={() => {
                    setDisabled(false);
                  }}
                  variant="outlined" 
                  color="primary" 
                  className={classes.button}
                  
                >
                  Edit
                </Button>}
                {(!itemExists || !disabled) && <ActionButton
                  onClick={done}
                  success={success}
                  loading={loading}
                >
                  Done
                </ActionButton>}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {loading && <LinearProgress color="secondary" />}
        {(() => {
          if(deleting) {
            return <LinearProgress color="secondary" />
          }

          if(loading) {
            return <div />
          }
          return (
            <div className={classes.contentWrapper}>
              <Grid direction="row" container spacing={6}>
                <Grid item xs={12} md={isCheckList() ? 12 : 6}>
                  <form onSubmit={done} className={classes.container} noValidate autoComplete="off" disabled={true}>
                    <button style={{display: 'none'}} type="submit" />
                    <WriteFormComponent 
                      schema={schema} 
                      handleFieldChange={handleFieldChange}
                      values={values}
                      errors={errors}
                      changeError={changeError}
                      merchantId={match.params.merchantId}
                      itemExists={itemExists}
                      itemId={match.params.itemId}
                      disabled={disabled}
                    />
                  </form>
                </Grid>
                {/* add in relationships here */}
                {(() => {

                  if(!itemExists) {
                    return <div />
                  }

                  return (
                    <Grid item xs={12} md={6}>
                      <RelationshipsComponent params={match.params} values={values} />
                    </Grid>
                  )
                })()}
              </Grid>
            </div>
          )
        })()}
    </div>
  );
}

export default compose(
  withSnackbar,
  withStyles(styles)
)(WriteComponent);
