import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import ProductsComponent from '../components/ProductsComponent';
import ManageTabComponent from '../components/ManageTabComponent';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { withSnackbar } from 'notistack';
import ViewTab from '../components/ViewTab';
import { LinearProgress, Button } from '@material-ui/core';
import forEach from 'lodash/forEach';
import isUndefined from 'lodash/isUndefined';
import TabsComponent from '../components/TabsComponent';

const Register = (props) => {
  const { match, enqueueSnackbar } = props;
  const [selectedProducts, setSelectedProducts] = useState({});
  const [selectedTab, setSelectedTab] = useState({})
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [merchant, setMerchant] = useState({});
  const [editingProducts, setEditingProducts] = useState(false);
  const [merchantRef, setMerchantRef] = useState(undefined);

  useEffect(() => {
    function handleData(doc) {
      setMerchant({
        merchant_id: doc.id,
        ...doc.data()
      });
      if(doc.data().new_tab) {
        setSelectedProducts(doc.data().new_tab);
      } else {
        setSelectedProducts({});
      }
      setLoading(false);
    }

    const merchantId = match.params.merchantId;

    setMerchantRef(firebase.firestore()
    .collection('merchants')
    .doc(merchantId));

    firebase.firestore()
      .collection('merchants')
      .doc(merchantId)
      .onSnapshot(handleData);
  }, [match.params.merchantId])

  function unselectAll() {
    setSelectedTab({});
    setSelectedProducts({});
  }

  function calculatePrice(collection) {
    let totalPrice = 0;
    forEach(collection, (data) => {
      console.log(data.qty);
      const quantityPrice = Number(data.price.floatValue) * Number(data.qty);
      totalPrice = Number(quantityPrice * 100) + totalPrice;
    });
    return {
      formatted: `$${Number(totalPrice / 100).toFixed(2)}`,
      unformatted: totalPrice
    }
  }

  function addToTab(product) {
    setLoading(true);
    const user = selectedTab;
    // if(user.products) {
    //   products = {...selectedProducts, ...user.products};
    // } else {
    //   products = selectedProducts;
    // }
    return firebase.firestore().collection('tabs').doc(`${match.params.merchantId}-${user.id}`).set({
      merchant_id: match.params.merchantId,
      user_id: user.id,
      display_name: user.display_name,
      merchant_name: merchant.merchant_name,
      products: {
        [`${product.id}`]: product
      },
      status: 'open'
    }, {merge: true})
    .then(() => {
      setLoading(false)
    })
    .catch(() => {
      setLoading(false)
      enqueueSnackbar("Oops! Something doesn't look right.", { 
        variant: 'error',
      });
    })
  }

  function viewTab() {
    setModalOpen(true);
  }

  useEffect(() => {
    function handleSuccess(doc) {
      if(doc.data() && doc.data().products) {
        setSelectedProducts(doc.data().products);
      } else {
        setSelectedProducts({});
      }
    }
    if(selectedTab.id) {
      setMerchantRef(firebase.firestore()
        .collection('tabs')
        .doc(`${merchant.merchant_id}-${selectedTab.id}`))
      return firebase.firestore()
        .collection('tabs')
        .doc(`${merchant.merchant_id}-${selectedTab.id}`)
        .onSnapshot(handleSuccess)
    }
  }, [selectedTab.id, merchant.merchant_id])

  return (
    <React.Fragment>
      <LinearProgress variant={loading ? 'query' : "determinate"} value={0} />
      {modalOpen && <ViewTab 
        open={modalOpen} 
        updateOpen={setModalOpen} 
        tab={selectedTab} 
        calculatePrice={calculatePrice}
        unselectAll={unselectAll}
      />}
      {(() => {
        if(isUndefined(merchant.stripe_user_id)) {
          return (
            <Grid 
              container 
              justify="center" 
              style={{
                margin: '50px 0 0 0'
              }}
            >
              <Grid item>
                <Button variant="contained" href="/onboarding" color="primary">
                  Accept Payments
                </Button>
              </Grid>
            </Grid>
          )
        }

        if(selectedTab.id) {
          return (
            <Grid container>
              <Grid item xs={12} md={8}>
                <ProductsComponent 
                  updateSelectedProducts={setSelectedProducts}
                  selectedProducts={selectedProducts}
                  updateEditingProducts={setEditingProducts}
                  merchantRef={merchantRef}
                  addToTab={addToTab}
                  updateSelectedUser={setSelectedTab}
                />
              </Grid>
              <Grid
                item 
                xs={12} 
                md={4}
                style={{
                  background: '#dff1f1',
                  minHeight: '100vh',
                  position: 'relative',
                  borderLeft: '1px solid #cccccc',
                  WebkitFilter: editingProducts ? 'blur(3px)' : 'none',
                  WozFilter: editingProducts ? 'blur(3px)' : 'none',
                  OFilter: editingProducts ? 'blur(3px)' : 'none',
                  MsFilter: editingProducts ? 'blur(3px)' : 'none',
                  filter: editingProducts ? 'blur(3px)' : 'none'
                }}
              >
                <ManageTabComponent 
                  updateSelectedUser={setSelectedTab}
                  selectedUser={selectedTab}
                  addToTab={addToTab}
                  viewTab={viewTab}
                  selectedProducts={selectedProducts}
                  updateSelectedProducts={setSelectedProducts}
                  merchant={merchant}
                  merchantRef={merchantRef}
                />
              </Grid>
            </Grid>
          )
        }

        return (
          <Grid container>
            <Grid item xs={12} md={12}>
              <TabsComponent 
                updateSelectedUser={setSelectedTab}
                selectedUser={selectedTab}
                addToTab={addToTab}
                viewTab={viewTab}
                selectedProducts={selectedProducts}
                updateSelectedProducts={setSelectedProducts}
                merchant={merchant}
                merchantRef={merchantRef}
              />
            </Grid>
            <Grid item xs={12} md={5}>

            </Grid>
          </Grid>
        )
        
      })()}
      
    </React.Fragment>
  )
}

export default withSnackbar(Register);
