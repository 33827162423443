import React from 'react';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';

import Chip from '@material-ui/core/Chip';

function FormatListValues(props) {
  const {row, field} = props;
  if(isUndefined(row[field.fieldId]) || isNull(row[field.fieldId]) || row[field.fieldId].length === 0) {
    return <span />
  } else if (!isUndefined(row[field.fieldId]) && (field.type.value === 'select' || field.type.value === 'tag') 
    && row[field.fieldId].length > 0) {
    return (
      <span>
        {row[field.fieldId].map(data => {
          return <Chip
            key={data.value}
            label={data.label}
            style={{
              margin: 5
            }}
          />
        })}
      </span>
    )
  } else if((field.type.value === 'select' || field.type.value === 'tag')&& isObject(row[field.fieldId])) {
    if(row[field.fieldId].label === '') return <span />;

    return <Chip
      label={row[field.fieldId].label}
      style={{
        margin: 5
      }}
    />
  } else if(field.type.value === 'dollar' && !field.type.raw) {
    return `$${row[field.fieldId].formatted}`
  } else if(field.type.value === 'dollar' && field.type.raw) {
    return `$${(Number(row[field.fieldId]) / 100).toFixed(2)}`
  } else if(field.type.value === 'select-stock' || field.type.value === 'select-product' || field.type.value === 'select-quantiy') {
    return String(row[field.fieldId])
  } else {
    return String(row[field.fieldId]);
  }
}

export default FormatListValues;