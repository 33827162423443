import firebase from 'firebase/app';
import 'firebase/firestore';

function Sale(merchantId) {
  return {
    title: 'Sales',
    asset: 'sales',
    canAdd: true,
    canEdit: false,
    canDelete: false,
    properties: [],
    default: [],
    schemaRef: firebase.firestore()
      .collection('attribute')
      .doc(merchantId)
      .collection('items')
      .where("fieldItem", "array-contains", {label: "Sales", value: "sales"}),
    dataRef:firebase.firestore()
      .collection('sales')
      .doc(merchantId),
  }
}

export default Sale;