import React, { useState, useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { InstantSearch, Configure, Stats } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import SearchBox from '../components/SearchBoxComponent';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
// import { Typography } from '@material-ui/core';
// import IconButton from '@material-ui/core/IconButton';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import { InfiniteHitsProducts,  TemplatedProductHits} from './InfiniteHitsProducts';
import {  TemplatedProductHits} from './InfiniteHitsProducts';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddProductComponent from './AddProductComponent';
import { IconButton, Button, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import isUndefined from 'lodash/isUndefined';
import EditProductComponent from './EditProductComponent';
import LinearProgress from '@material-ui/core/LinearProgress';

const algoliaClient = algoliasearch(
  'IC1SHLYR5C',
  'fd74929dbaf31ec517735d514d9dd8e2'
);

let firstLoad = true;

const searchClient = {
  search(requests) {
    if (firstLoad && requests[0].params.query !== '') {
      firstLoad = false;
    }
    if (firstLoad && requests[0].params.query === '') {
      return;
    }
    algoliaClient.clearCache();
    return algoliaClient.search(requests);
  },
};

const styles = theme => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addItem: {
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
    padding: '10px 20px',
  },
  contentWrapper: {
    margin: 0,
    position: 'relative',
    textAlign: 'center',
    // overflow: 'scroll',
    maxWidth: '100%'
  },
  mainContent: {
    flex: 1,
    padding: '48px 36px 0'
  },
  deletingProducts: {
    margin: 0,
    padding: 0,
    minHeight: '100%',
    // eslint-disable-next-line
    minHeight: 'calc(100vh - 79px)'
  },
  deletingProductsTitle: {
    margin: '20px 0',
    padding: 20,
  }
});

const ProductsComponent = (props) => {
  // const { schema, assetType, merchantId } = this.state;
  const { classes, match, selectedProducts, updateEditingProducts, addToTab, updateSelectedUser } = props;
  const [stateOfSearch, setStateOfSearch] = useState({
    query: '',
    refinementList: {
      asset: 'products',
      merchantId: match.params.merchantId
    },
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editProductId, setEditProductId] = useState('');

  useEffect(() => {
    if(isUndefined(stateOfSearch.query)) {
      let newStateOfSearch = stateOfSearch;
      newStateOfSearch.query = '';
      setStateOfSearch({
        ...newStateOfSearch
      })
    }
  }, [stateOfSearch])

  function handleSearchChange(searchState) {
    setStateOfSearch(searchState);
  }

  useEffect(() => {
    updateEditingProducts(editing);
  }, [editing, updateEditingProducts])

  useEffect(() => {
    function handleData(query) {
      let newData = [];
      query.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data()
        })
      });
      setLoading(false);
      setData(newData);
    }

    const unsubscribe = firebase.firestore()
      .collection('product')
      .doc(match.params.merchantId)
      .collection('items')
      .onSnapshot(handleData);

    return unsubscribe;
  }, [match.params.merchantId])

  // function removeItem(row) {
  //   return firebase.firestore()
  //     .collection('product')
  //     .doc(match.params.merchantId)
  //     .collection('items')
  //     .doc(row.id)
  //     .delete();
  // }

  function productAction(row) {
    if(editing) {
      setEditModalOpen(true);
      setEditProductId(row.id);
      return;
    }

    let newQty = 1;
    if (!isUndefined(selectedProducts[row.id])) {
      newQty = firebase.firestore.FieldValue.increment(1);
    }

    addToTab({
        id: row.id, 
        selected: true, 
        price: row.price, 
        name: row.name, 
        qty: newQty
    });
  }

  return (
    <InstantSearch
      indexName="opel_merchant"
      searchClient={searchClient}
      searchState={stateOfSearch}
      onSearchStateChange={handleSearchChange}
      refresh={true}
    >
      <EditProductComponent 
        open={editModalOpen} 
        updateOpen={setEditModalOpen}
        productId={editProductId}
      />
      <Configure 
        filters={`asset:products AND merchantId:${match.params.merchantId}`}
      />
      {/* <Paper className={classes.paper}> */}
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar disableGutters>
          {editing && <Grid container  spacing={1} alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h6">
                SELECT ITEM TO EDIT
              </Typography>
              <LinearProgress color="secondary" />
            </Grid>
            <Grid item>
              <Button onClick={() => setEditing(false)}>
                Done
              </Button>
            </Grid>
          </Grid>}
          {!editing && <Grid container  spacing={2} alignItems="center" justify="flex-start">
            <Grid item xs={3}>
              <Button 
                variant="contained"
                fullWidth
                onClick={() => updateSelectedUser({})}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <SearchIcon className={classes.block} color="inherit" />
            </Grid>
            <Grid item xs>
              <SearchBox noSaveState={true} placeholder={`Search products`} searchAsYouType={true} />
            </Grid>
            {(stateOfSearch.query !== '') && <Grid item>
              <Stats
                translations={{
                  stats(nbHits, timeSpentMS) {
                    return `${nbHits} results`;
                  },
                }}
              />
            </Grid>}
            <Grid item>
              <IconButton onClick={() => setEditing(true)}>
                <EditIcon />
              </IconButton>
            </Grid>
            {!editing && <Grid item>
              <AddProductComponent />
            </Grid>}
          </Grid>}
        </Toolbar>
      </AppBar>
      <div className={clsx(classes.contentWrapper, (editing) ? classes.deletingProducts : undefined)}>
        {(() => {
          if(loading) {
            return <CircularProgress />;
          }

          if(stateOfSearch.query === '') {
            return <TemplatedProductHits 
              hits={data} 
              columns={3} 
              action={productAction} 
              selected={selectedProducts}
              selectedColor="#154f5a"
              avatar={false}
              editing={editing}
            />;
          }

          return (
            <Typography variant="subtitle1" align="center">
              <br />
              This doesn't work yet! 
              <br />
              Blame Garrison he made me.

              Also, ask for this if you want it. Bug Garrison.
            </Typography>
          )

          // return <InfiniteHitsProducts 
          //   columns={3}
          //   action={productAction}
          //   selected={selectedProducts}
          //   avatar={false}
          //   selectedColor="#154f5a"
          // />;
        })()}
      </div>
      {/* </Paper> */}
    </InstantSearch>
  )
}


export default compose (
  withRouter,
  withStyles(styles)
)(ProductsComponent);
