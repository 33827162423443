import React, { Component } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

export class BooleanComponent extends Component {
  render() {
    const { value, required, error, fieldName, onChange, fieldId, disabled } = this.props;
    return (
      <FormControl
        fullWidth
        style={{
          marginTop: 10
        }}
        component="fieldset"
        error={error}
      >
        <FormLabel required={required} component="label">{fieldName}</FormLabel>
        <FormGroup>
          <FormControlLabel
            style={{
              textTransform: 'capitalize'
            }}
            control={
              <Switch
                required={required}
                checked={value}
                value={value}
                onChange={(e) => onChange(fieldId, e)}
                color="secondary"
                disabled={disabled}
              />
            } 
            key={fieldId}
          >
            {value}
          </FormControlLabel>
        </FormGroup>
        {(() => {
          if(error.error) {
            return <FormHelperText>{error.helperText}</FormHelperText>
          }
        })()}
      </FormControl>
    )
  }
}

export default BooleanComponent
