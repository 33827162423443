import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import isObject from 'lodash/isObject';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalSeparator="."
      allowEmptyFormatting={true}
      allowNegative={false}
      prefix="$"
    />
  );
}

export class DollarComponent extends Component {
  render() {
    const { value, required, error, helperText, fieldName, onChange, fieldId, disabled } = this.props;
    return (
      // <FormControl 
      //   fullWidth
      //   error={error}
      // >
      //   <InputLabel required={required} htmlFor="adornment-amount">{fieldName}</InputLabel>
      //   <Input
      //     id="adornment-amount"
      //     value={value}
          
      //     startAdornment={<InputAdornment position="start">$</InputAdornment>}
      //     type="number"
      //   />
      //   {(() => {
      //     if(error) {
      //       return <FormHelperText>{error.helperText}</FormHelperText>
      //     }
      //   })()}
      // </FormControl>
      <TextField
        fullWidth
        label={fieldName}
        required={required}
        value={isObject(value) ? value.formatted : (value / 100).toFixed(2)}
        error={error}
        helperText={helperText}
        placeholder="$0.00"
        margin="normal"
        onChange={(e) => onChange(fieldId, e)}
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        disabled={disabled}
      />
    )
  }
}

export default DollarComponent
