import React, { useEffect, useState } from 'react';
import { u } from 'url-param';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from "react-router-dom";



const Onboarding = (props) => {
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [merchant, setMerchant] = useState(undefined);

  useEffect(() => {
    function handleMerchant(doc) {
      setMerchant(doc.data());
      setLoading(false);
    }
    if(user && user.merchant_id) {
      firebase.firestore()
        .collection('merchants')
        .doc(user.merchant_id)
        .get()
        .then(handleMerchant);
    }
  }, [user])

  useEffect(() => {
    function handleUser(doc) {
      setUser(doc.data());
      if(!doc.data().merchant_id) {
        setLoading(false);
      }
    }
    
    firebase.firestore()
    .collection('users')
    .doc(firebase.auth().currentUser.uid)
    .get()
    .then(handleUser);
  }, [])

  useEffect(() => {
    const paramsObj = u(props.location.search);
    if(paramsObj.code && user && user.merchant_id && merchant && !merchant.stripe_user_id) {
      var connectAccount = firebase.functions().httpsCallable('connectAccount');
      connectAccount({code: paramsObj.code, merchantId: user.merchant_id}).then(function(res) {
        // Read result of the Cloud Function.
        window.location = "https://merchant.opel.org";
        // ...
      });
    }
  }, [props.location.search, user, merchant]);

  if(loading) {
    return <CircularProgress
      size={100}
      thickness={10}
      color="secondary"
      style={{
        margin: 'auto',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }}
    />
  }

  if(merchant && merchant.stripe_user_id) {
    return <Redirect to={`/${merchant.merchant_id}/transaction`} />
  }


  return (
    <div style={{marginTop: 50, textAlign: 'center'}}>
      <p>
        <a href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_EGQSdJgJq5sMCL5KaX2717W5EMMM0z5Y&scope=read_write" className="stripe-connect"><span>Connect with Stripe</span></a>
      </p>
    </div>
  )
}

export default Onboarding
