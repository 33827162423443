import React, { Component } from 'react';
import { Route, Redirect } from "react-router-dom";
import Assets from '../Routes/Assets';
import CheckList from '../Routes/CheckList';
import Onboarding from '../Routes/Onboarding';
import Settings from '../Routes/Settings';
import Register from '../Routes/Register';


export class UIRouter extends Component {

  render() {
    const { userData } = this.props;

    return (
      <div>
        <Route exact path="/"
          render={
            props => <Redirect to={`/${userData.merchantId}/register`} />
          }
        />
        <Route exact path={`/onboarding`} component={Onboarding} />
        <Route path={`/:merchantId/`} 
          render={
            props => {
              const { match } = props;
              if(match.params.merchantId !== userData.merchantId) {
                return <Redirect to={`/${userData.merchantId}/register`} />
              }
              return <div>
                <Route path={`/:merchantId/settings`} component={Settings} />
                <Route path={`/:merchantId/:assetType(stock)`} component={Assets}/>
                {/* <Route path={`${match.url}/product`} component={Assets}/> */}
                <Route path={`/:merchantId/:assetType(attribute)`} component={Assets}/>
                <Route path={`/:merchantId/:assetType(quantity)`} component={Assets}/>
                <Route path={`/:merchantId/:assetType(report)`} component={Assets}/>
                <Route path={`/:merchantId/:assetType(transaction)`} component={Assets}/>
                <Route path={`/:merchantId/checklist/:checkListType`} component={CheckList}/>
                <Route path={`/:merchantId/register`} component={Register} />
                {/* <Route path={`${match.url}/:assetType(sale)`} component={Sales}/> */}
              </div>
            }
          }
        />
      </div>
    )
  }
}

export default UIRouter