import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

export class AlertComponent extends Component {
  constructor(props) {
    super(props);
    this.handleDone = this.handleDone.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleDone() {
    this.props.dialog.success();
  }

  handleClose = () => {
    this.props.dialog.dismiss();
  };

  render() {
    const { dialog } = this.props;

    return (
      <Dialog open={dialog.open} onClose={this.handleClose} aria-labelledby="simple-dialog-title" >
        <DialogTitle id="simple-dialog-title">{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {dialog.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.cancel && <Button onClick={this.handleClose} color="primary">
            {dialog.cancel}
          </Button>}
          {dialog.done && <Button onClick={this.handleDone} color="primary">
            {dialog.done}
          </Button>}
        </DialogActions>
      </Dialog>
    )
  }
}

export default AlertComponent
