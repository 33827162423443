import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Navigator from './Navigator';
import UIRouter from './UIRouter';
import { BrowserRouter as Router } from "react-router-dom";
import clsx from 'clsx';

const openDrawerWidth = 176;
const closeDrawerWidth = 55;


const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    width: openDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: openDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRight: 0
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: closeDrawerWidth,
    borderRight: 0,
    [theme.breakpoints.up('sm')]: {
      width: closeDrawerWidth,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100% - ${openDrawerWidth}px)`,
    maxWidth: '100%',
    overflow: 'scroll'
  },
  appContentOpen: {
    maxWidth: `100%`,
    width: `calc(100% - ${openDrawerWidth}px)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appContentClose: {
    maxWidth: `100%`,
    width: `calc(100% - ${closeDrawerWidth}px)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  router: {
  }
});

class Paperbase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      isSignedIn: false
    }
  }

  toggleWidth = () => {
    console.log('1');
    this.setState({
      expanded: !this.state.expanded
    })
  }

  render() {
    const { classes, userData } = this.props;

    return (
      <div>
        <Router>
          <div className={classes.root}>
            <CssBaseline />
            <nav
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: this.state.expanded,
                [classes.drawerClose]: !this.state.expanded
              })}
              classes={{
                drawerPaper: clsx({
                  [classes.drawerOpen]: this.state.expanded,
                  [classes.drawerClose]: !this.state.expanded
                })
              }}
            >
              {/* <Hidden smUp implementation="css">
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                  merchantId={userData.merchantId} 
                />
              </Hidden> */}
              <Navigator
                PaperProps={{ style: { width: (this.state.expanded) ? 180 : 20 } }}
                variant="permanent"
                merchantId={userData.merchantId} 
                toggleWidth={this.toggleWidth}
                expanded={this.state.expanded}
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: this.state.expanded,
                  [classes.drawerClose]: !this.state.expanded
                })}
                classes={{
                  drawerPaper: clsx({
                    [classes.drawerOpen]: this.state.expanded,
                    [classes.drawerClose]: !this.state.expanded
                  })
                }}
              />
            </nav>
            <div
              className={clsx(classes.appContent, {
                [classes.appContentOpen]: this.state.expanded,
                [classes.appContentClose]: !this.state.expanded
              })}
              classes={{
                drawerPaper: clsx({
                  [classes.appContentOpen]: this.state.expanded,
                  [classes.appContentClose]: !this.state.expanded
                })
              }}
            >
              {/* <Header 
                onDrawerToggle={this.handleDrawerToggle} 
                title={'hello'} 
                merchantId={userData.merchantId}
              /> */}
              <UIRouter className={classes.router} userData={userData} />
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);
