import firebase from 'firebase/app';
import 'firebase/firestore';

function open(merchantId) {
  return {
    title: 'Open',
    asset: 'open',
    canEdit: true, 
    canAdd: true,
    canDelete: false,
    properties: [],
    default: [
      {
        type: {
          label: 'Text',
          value: 'Text'
        },
        fieldId: "name",
        fieldName: "Your name:",
        placeholder: "type something amazing...",
        required: true,
        priority: 100
      }
    ],
    schemaRef: firebase.firestore()
      .collection('attribute')
      .doc(merchantId)
      .collection('items')
      .where("fieldItem", "array-contains", {label: "Open", value: "open"}),
    dataRef:firebase.firestore()
      .collection('open')
      .doc(merchantId)
  }
}

function close(merchantId) {
  return {
    title: 'Close',
    asset: 'close',
    canEdit: true,
    canAdd: true,
    canDelete: false,
    properties: [],
    default: [
      {
        type: {
          label: 'Text',
          value: 'Text'
        },
        fieldId: "name",
        fieldName: "Your name:",
        placeholder: "type something amazing...",
        required: true,
        priority: 100
      }
    ],
    schemaRef: firebase.firestore()
      .collection('attribute')
      .doc(merchantId)
      .collection('items')
      .where("fieldItem", "array-contains", {label: "Close", value: "close"}),
    dataRef:firebase.firestore()
      .collection('close')
      .doc(merchantId)
  }
}

export { open, close };