import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NumberFormat from 'react-number-format';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {withRouter} from 'react-router-dom';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values,
          },
        });
      }}
      thousandSeparator
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
      allowNegative={false}
      prefix="$"
    />
  );
}


function AddProductComponent(props) {
  const { match } = props;
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState({value: ''});
  const [name, setName] = useState('');


  function handleClickOpen() {
    setOpen(true);
  };

  function handleClose() {
    setOpen(false)
  };

  function formSubmit(e) {
    e.preventDefault();

    firebase.firestore()
      .collection('product')
      .doc(match.params.merchantId)
      .collection('items')
      .add({
        price: amount,
        name: name
      })
      .then(() => {
        setOpen(false);
        setName('');
        setAmount({value: ''});
      });
  }

  return (
    <div>
      <Button 
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        Add
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={formSubmit}>
          <DialogTitle id="form-dialog-title">Add Product</DialogTitle>
          <DialogContent>
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              helperText={`Name of the product`}
            />
            <TextField
              fullWidth
              label={`Price`}
              required
              helperText={`Enter a price`}
              placeholder="$0.00"
              margin="normal"
              id="formatted-numberformat-input"
              value={amount.value}
              onChange={(e) => setAmount(e.target.value)}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button 
              type="submit"
              color="primary"
            >
              Done
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default withRouter(AddProductComponent)