
function FormatFields(field, value) {
  let data;
  switch(field.type.value) {
    case 'dollar':
      const singleValue = Number(value);
      data = {
        formatted: (value === '') ? value : singleValue,
        unformatted: Number((singleValue * 100).toFixed())
      }
      break;
    case 'number':
      // Need to do some work with this "Number concept"
      data = Number(value);
      break;
    case 'checkbox':
    case 'unique':
    case 'text':
    case 'tag':
    case 'select':
    default:
      data = value;
      break;
  }

  return data;

}

export default FormatFields;