import product from "./product";
import stock from "./stock";
import quantity from "./quantity";
import attribute from "./attribute";
import sale from "./sale"
import report from "./report";
import {open, close} from "./checklist";
import transaction from "./transaction";

export default function(merchantId, type) {
  let defaultSchema;
  switch (type) {
    case 'quantity':
      defaultSchema = quantity(merchantId);
      break;
    case 'stock':
      defaultSchema = stock(merchantId);
      break;
    case 'product':
      defaultSchema = product(merchantId);
      break;
    case 'attribute':
      defaultSchema = attribute(merchantId);
      break;
    case 'sale':
      defaultSchema = sale(merchantId);
      break;
    case 'report':
      defaultSchema = report(merchantId);
      break;
    case 'open':
      defaultSchema = open(merchantId);
      break;
    case 'close':
      defaultSchema = close(merchantId);
      break;
    case 'transaction':
      defaultSchema = transaction(merchantId);
      break;
    default:
      return false;
  }

  return defaultSchema;
}