import firebase from 'firebase/app';
import 'firebase/firestore';

function transaction(merchantId) {
  return {
    title: 'Transaction',
    asset: 'transaction',
    canAdd: false,
    canEdit: false,
    canDelete: false,
    properties: [],
    default: [
      {
        type: {
          label: 'Text',
          value: 'text'
        },
        fieldId: "user_name",
        fieldName: "Customer Name",
        required: true,
        priority: 300
      },
      {
        type: {
          value: 'text',
          label: 'text'
        },
        fieldId: "status",
        fieldName: "Status",
        required: false,
        priority: 301
      },
      {
        type: {
          value: 'dollar',
          label: 'Dollar',
          raw: true
        },
        fieldId: "amount",
        fieldName: "Amount",
        required: true,
        priority: 297
      },
      {
        type: {
          value: 'dollar',
          label: 'Dollar',
          raw: true
        },
        fieldId: "tip",
        fieldName: "Tip",
        required: true,
      },
      {
        type: {
          value: 'dollar',
          label: 'Dollar',
          raw: true
        },
        fieldId: "member_fee",
        fieldName: "Opel Member Fee",
        required: true,
      },
      {
        type: {
          value: 'dollar',
          label: 'Dollar',
          raw: true
        },
        fieldId: "total",
        fieldName: "Total",
        required: true,
        priority: 297
      }
    ],
    schemaRef: firebase.firestore()
      .collection('attribute')
      .doc(merchantId)
      .collection('items')
      .where("fieldItem", "array-contains", {label: "Transaction", value: "transaction"}),
    dataRef: firebase.firestore()
      .collection('transactions')
  }
  
};

export default transaction;