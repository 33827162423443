import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NumberFormat from 'react-number-format';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { withRouter } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values,
          },
        });
      }}
      thousandSeparator
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
      allowNegative={false}
      prefix="$"
    />
  );
}


function EditProductComponent(props) {
  const { match, open, updateOpen, productId } = props;
  const [amount, setAmount] = useState({value: ''});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [name, setName] = useState('');


  useEffect(() => {
    function handleData(doc) {
      setAmount(doc.data().price);
      setName(doc.data().name);
    }

    if(open) {
      firebase.firestore()
        .collection('product')
        .doc(match.params.merchantId)
        .collection('items')
        .doc(productId)
        .get()
        .then(handleData);
    }

    if(!open) {
      setConfirmDelete(false);
    }

  }, [productId, match.params.merchantId, open])

  function handleClose() {
    updateOpen(false)
  };

  function formSubmit(e) {
    e.preventDefault();

    firebase.firestore()
      .collection('product')
      .doc(match.params.merchantId)
      .collection('items')
      .doc(productId)
      .update({
        price: amount,
        name: name
      })
      .then(() => {
        handleClose();
        setName('');
        setAmount({value: ''});
      });
  }

  function deleteProduct() {
    firebase.firestore()
      .collection('product')
      .doc(match.params.merchantId)
      .collection('items')
      .doc(productId)
      .delete()
      .then(() => {
        handleClose();
        setName('');
        setAmount({value: ''});
      });
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {confirmDelete && <form onSubmit={formSubmit}>
          <DialogTitle id="form-dialog-title">
            Deleting Product
          </DialogTitle>
          <DialogContent>
            Are you sure you want to delete this?
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setConfirmDelete(false)}
              color="primary"
            >
              Nevermind
            </Button>
            <Button
              onClick={deleteProduct}
              color="primary"
            >
              Delete it!
            </Button>
          </DialogActions>
        </form>}
        {!confirmDelete && <form onSubmit={formSubmit}>
          <DialogTitle id="form-dialog-title">
            Edit Product
            <IconButton onClick={() => setConfirmDelete(true)}>
              <DeleteIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              helperText={`Name of the product`}
            />
            <TextField
              fullWidth
              label={`Price`}
              required
              helperText={`Enter a price`}
              placeholder="$0.00"
              margin="normal"
              id="formatted-numberformat-input"
              value={amount.value}
              onChange={(e) => setAmount(e.target.value)}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button 
              type="submit"
              color="primary"
            >
              Done
            </Button>
          </DialogActions>
        </form>}
      </Dialog>
    </div>
  );
}

export default withRouter(EditProductComponent)