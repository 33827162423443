import firebase from 'firebase/app';
import 'firebase/firestore';

function attribute(merchantId) {
  return {
    title: "Attribute",
    asset: 'attribute',
    canAdd: true,
    canEdit: true,
    canDelete: true,
    properties: [],
    default: [
      {
        type: {
          label: 'Text',
          value: 'Text'
        },
        fieldId: "fieldName",
        fieldName: "Field Name",
        placeholder: "type something amazing...",
        required: true,
        unique: true,
        priority: 100
      },
      {
        type: {
          label: 'Select',
          value: 'select'
        },
        fieldId: "type",
        fieldName: "Type",
        required: true,
        priority: 0,
        options: [
          {
            value: 'text',
            label: 'Text'
          },
          {
            value: 'number',
            label: 'Number'
          },
          {
            value: 'dollar',
            label: 'Dollar'
          },
          {
            value: 'tag',
            label: 'Tag'
          },
          {
            value: 'unique',
            label: 'Unique'
          },
          {
            label: 'Checkbox',
            value: 'checkbox'
          }
          // {
          //   value: 'select',
          //   label: 'Select'
          // },
          // {
          //   value: 'tag',
          //   label: 'Tag Field'
          // }
        ]
      },
      {
        type: {
          label: 'Checkbox',
          value: 'checkbox'
        },
        fieldId: "required",
        fieldName: "Required",
        required: true,
        priority: 0
      },
      {
        type: {
          label: 'Select',
          value: 'select'
        },
        multiple: true,
        fieldId: "fieldItem",
        fieldName: "Section",
        placeholder: "Stock / Product",
        required: true,
        priority: 0,
        options: [
          {
            value: 'stock',
            label: 'Stock'
          },
          {
            value: "quantity",
            label: "Quantity"
          },
          {
            value: "open",
            label: "Open"
          },
          {
            value: "close",
            label: "Close"
          }
        ]
      },
      {
        type: {
          label: 'Number',
          value: 'number'
        },
        fieldId: "priority",
        fieldName: "Priority",
        required: true,
        unique: false,
        priority: 0
  
      }
    ],
    schemaRef: firebase.firestore()
      .collection('attribute')
      .doc(merchantId)
      .collection('items')
      .where("fieldItem", "array-contains", {label: "Attribute", value: "attribute"}),
    dataRef:firebase.firestore()
      .collection('attribute')
      .doc(merchantId)
  
  
  }
}

export default attribute;