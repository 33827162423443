import React, { useEffect, useState } from 'react';
import { InstantSearch, Configure, connectAutoComplete } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from '@material-ui/icons/Tune';
import DefaultSchema from '../../Schema';
import SimpleAssetListComponent from '../SimpleAssetListComponent';

const searchClient = algoliasearch(
  'IC1SHLYR5C',
  'fd74929dbaf31ec517735d514d9dd8e2'
);

const styles = {
  parent: {
    marginTop: 15,
    marginBottom: 15
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  results: {
    borderTop: '1px solid #cccccc',
    padding: 10,
  }
};

function CustomAutoComplete(props) {
  const [touched, setTouched ] = useState(false)
  const { hits, currentRefinement, refine, schema, classes, onChange, value, disabled } = props;

  function selectedItem(row) {
    refine(`${row.name} - ${row.sku}`);
    onChange(`${row.name} - ${row.sku}`, row);
  }

  return (
    <div className={classes.parent}>
      <Paper elevation={2}>
        <div className={classes.root}>
          {/* <IconButton className={classes.iconButton} aria-label="Menu">
            <MenuIcon />
          </IconButton> */}
          <IconButton className={classes.iconButton} aria-label="Search">
            <TuneIcon />
          </IconButton>
          <InputBase disabled={disabled} value={currentRefinement} className={classes.input} placeholder="Search for stock" onChange={e => {refine(e.target.value); setTouched(true) }} />
        </div>
        {(() => {
          if(touched) {
            return <SimpleAssetListComponent hits={hits} schema={schema} selectedItem={selectedItem} value={value} />
          }
        })()}
      </Paper>
    </div>
  )
}

const ConnectAutoComplete = compose(
  connectAutoComplete,
  withStyles(styles)
)(CustomAutoComplete)

function AssetSelectComponent(props) {
  const { assetType, onChange, value, disabled } = props;
  // So arrayContains would be an object
  const merchantId = props.match.params.merchantId;
  const [schema, setSchema] = useState(DefaultSchema(merchantId, assetType.value));

  useEffect(() => {
    function handleSchemaChange(query) {
      let newSchema = schema;
      let data = [];
      if(!query.empty) {
        query.forEach((doc) => {
          data.push({id: doc.id, ...doc.data()});
        })
      }
      newSchema.properties = [...schema.default, ...data];
      setSchema(newSchema);
    }

    firebase.firestore()
      .collection('attribute')
      .doc(merchantId)
      .collection('items')
      .where("fieldItem", "array-contains", assetType)
      .get({source: "server"})
      .then(handleSchemaChange);
    return;
  }, [assetType, merchantId, schema]);

  return (
    <InstantSearch
      indexName="opel_merchant"
      searchClient={searchClient}
    >
      <Configure 
        filters={`asset:${assetType.value}`}
      />
      <ConnectAutoComplete 
        disabled={disabled} 
        defaultRefinement={value} 
        onChange={onChange} 
        value={value} 
        schema={schema}
      />
    </InstantSearch>
  )
}

export default withRouter(AssetSelectComponent);