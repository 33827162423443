import React from 'react';

import { Typography, Button } from "@material-ui/core";

import LogoIcon from '../theme/LogoIcon';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error);
    console.log(info);
    // firebase.database().ref('errors').push({
    //   error: error,
    //   info: info, 
    //   user_id: firebase.auth().currentUser.uid,
    //   user_email: firebase.auth().currentUser.email,
    //   user_phone: firebase.auth().currentUser.phoneNumber,
    //   created_at: firebase.database.ServerValue.TIMESTAMP
    // });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <br />
          <br />
          <LogoIcon size={150} center />
          <br />
          <br />
          <Typography variant="h4" align="center">
            An Error Has Occurred:
          </Typography>
          <div style={{textAlign: 'center', margin: '40px auto', width: '50%'}}>
            <Button fullWidth variant="contained" onClick={() => window.location.reload(true)} style={{padding: 20}}>Update App</Button>
          </div>
        </div>
      );
    }

    return this.props.children; 
  }
}