

import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import isEqual from 'lodash/isEqual';

function UniqueInputComponent(props) {
  const [oldValue] = useState('');
  const [unique, setUnique] = useState(true);
  const { 
    value, 
    required, 
    error, 
    fieldName, 
    onChange, 
    fieldId, 
    firestoreRef, 
    changeError, 
    isQueryArray,
    disabled,
    itemId
  } = props;


  

  useEffect(() => {
    if((!unique && !error) || (unique && error)) {
      console.log('1');
      changeError({
        error: !unique
      }, fieldId)
    }
  }, [unique, fieldId, changeError, error])

  useEffect(() => {
    function isCurrentItem(id) {
      return id === itemId;
    }

    function isUnique() {
      return firestoreRef
        .collection('items')
        .where(fieldId, `${isQueryArray ? 'array-contains' : '=='}`, value)
        .get()
        .then((query) => {
          if(query.size > 1) {
            setUnique(false);
          } else if (query.size === 1) {
            query.forEach((doc) => {
              isCurrentItem(doc.id) ? setUnique(true) : setUnique(false)
            })
          } else {
            setUnique(true);
          }
      })
    }

    if(!isEqual(value, oldValue)) {
      isUnique();
    }
  }, [value, oldValue, fieldId, firestoreRef, isQueryArray, itemId])

  return (
    <div>
      <TextField
        fullWidth
        label={fieldName}
        required={required}
        value={value}
        error={!unique ? true : error}
        helperText={!unique ? "Oops. This isn't unique" : "Looks good"}
        placeholder="A unique field"
        onChange={(e) => onChange(fieldId, e)}
        margin="normal"
        disabled={disabled}
        autoFocus={props.autoFocus}
      />
    </div>
  )
}

export default UniqueInputComponent;
