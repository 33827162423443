import React from 'react';
import { ListItem, List, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';


function SimpleAssetListComponent(props) {
  const { hits, classes, schema, selectedItem, value = {id: null}} = props;

  
  return (
    <List className={classes.results}>
      {hits.map(row => {
        return (
          <ListItem key={row.id} selected={(row.id === value.id)}  button onClick={() => selectedItem(row)} divider>
            {schema.properties.map((field, index) => {
              if(isUndefined(row[field.fieldId])) {
                return <p key={index} />
              } else if ((field.type.value === 'select' || field.type.value === 'tag') 
                && row[field.id].length > 0) {
                return (
                  <p key={field.id}>
                    {row[field.id].map(data => {
                      return <Chip
                        key={data.value}
                        label={data.label}
                        style={{
                          margin: 5
                        }}
                      />
                    })}
                  </p>
                )
              } else if(field.type.value === 'select' && isObject(row[field.id])) {
                return <p key={index}>{row[field.fieldId].label}</p>
              } else if(field.type.value === 'dollar') {
                return <Chip
                  style={{
                    marginRight: 3
                  }}
                  key={index}
                  label={`${field.fieldName}:  $${String(row[field.fieldId].formatted)}`}
                />
              } else {
                return <Chip
                  style={{
                    marginRight: 3
                  }}
                  key={index}
                  label={`${field.fieldName}:  ${String(row[field.fieldId])}`}
                />
              }
            })}
          </ListItem>
        )
      })}
    </List>
  );
}

const styles = {
  parent: {
    marginTop: 15,
    marginBottom: 15
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  results: {
    borderTop: '1px solid #cccccc',
    padding: 10,
  }
};

export default withStyles(styles)(SimpleAssetListComponent)